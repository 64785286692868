/* eslint-disable no-console */
// eslint-disable-next-line import/no-extraneous-dependencies
import MainModel from '@tripian/model';

const easy = {
  /**
   * Push message to tripian.data.log array.
   * If NODE_ENV is development, write message to console.
   * @param {object} message
   */
  log(message: object) {
    // // data.logs.push(...message);
    if (process.env.NODE_ENV === 'development') {
      // eslint-disable-next-line no-console
      console.log(message);
    }
  },

  /**
   * Push error message to tripian.data.errors array.
   * If NODE_ENV is development, write error message to console.
   * @param {object} message
   */
  error(message: object) {
    // data.errors.push(...message);
    if (process.env.NODE_ENV === 'development') {
      // eslint-disable-next-line no-console
      console.error(message);
    }
  },

  /**
   * Replace stringQuery, param.key with param.value.
   * @param {string} stringQuery
   * @param {object} param { key, value }
   * @returns {string} stringQuery.replace(`{${param.key}}`, param.value)
   */
  setParameter(stringQuery: string, param: { key: string; value: string }): string {
    return stringQuery.replace(`{${param.key}}`, param.value);
  },

  /**
   * Replace stringQuery, '{id}' with id
   * @param {string} stringQuery
   * @param {number} id
   * @returns {string} stringQuery.replace('{id}', id);
   */
  setParameterId(stringQuery: string, id: number): string {
    return stringQuery.replace('{id}', id.toString());
  },

  /**
   * Replace stringQuery, all param.key with all param.value foreach params parameter
   * @param {string} stringQuery
   * @param {object} params [{ key, value }, { key, value } ...]
   * @returns {string} stringQuery.replace(`{${param.key}}`, param.value)
   */
  setParameters(stringQuery: string, params: [{ key: string; value: string }, { key: string; value: string }]): string {
    return params.reduce((previoueValue, currentValue) => this.setParameter(previoueValue, currentValue), stringQuery);
  },

  /**
   * Capitalize first letter of string param
   * @param {string} any string
   * @returns {string} first letter is uppercase
   */
  capitalizeFirstLetter(string: string): string {
    return string.charAt(0).toUpperCase() + string.slice(1);
  },

  parseAccessToken(accessToken: string): MainModel.TokenPayload | undefined {
    try {
      const base64Url: string = accessToken.split('.')[1];
      const base64: string = base64Url.replace(/-/g, '+').replace(/_/g, '/');
      const encodedURIComponent: string = atob(base64)
        .split('')
        .map((c) => `%${`00${c.charCodeAt(0).toString(16)}`.slice(-2)}`)
        .join('');
      const jsonPayload: string = decodeURIComponent(encodedURIComponent);
      return JSON.parse(jsonPayload);
    } catch (error) {
      console.log('Invalid access token');
      return undefined;
    }
  },

  parseToken(token: MainModel.Token): MainModel.TokenPayload | undefined {
    const accessToken = token.access_token;
    return this.parseAccessToken(accessToken);
  },

  accessTokenExpSecond(tokenPayload: MainModel.TokenPayload): number {
    const now = Math.floor(Date.now() / 1000);
    return tokenPayload.exp - now;
  },

  refreshTokenExpSecond(tokenPayload: MainModel.TokenPayload): number {
    const refreshTokenValidationDuration = 2592000; // 60*60*24*30 (1 month)
    const refreshTokenExpireTime = tokenPayload?.auth_time + refreshTokenValidationDuration;
    const now = Math.floor(Date.now() / 1000);
    return refreshTokenExpireTime - now;
  },
};

export default easy;
