import Model, { v3 } from '@tripian/model';

const convert = {
  convertPoiCategories: (poiCategories: v3.PoiCategory[]): Model.PoiCategory[] => poiCategories as Model.PoiCategory[],

  convertPoi: (poi: v3.Poi): Model.Poi => poi as Model.Poi,

  convertPois: (pois: v3.Poi[]): Model.Poi[] => pois as Model.Poi[],

  convertUser: (user: v3.User): Model.User => user as Model.User,

  convertQuestions: (questions: v3.Question[]): Model.Question[] => questions as Model.Question[],

  convertCity: (city: v3.City): Model.City => city as Model.City,

  convertCities: (cities: v3.City[]): Model.City[] => cities as Model.City[],

  convertStep: (step: v3.Step): Model.Step => step as Model.Step,

  convertSteps: (steps: Array<v3.Step>): Array<Model.Step> => steps as Array<Model.Step>,

  convertPlan: (plan: v3.Plan): Model.Plan => plan as Model.Plan,

  convertPlans: (plans: Array<v3.Plan>): Array<Model.Plan> => plans as Array<Model.Plan>,

  convertTrip: (trip: v3.Trip): Model.Trip => trip as Model.Trip,

  convertTripRefs: (tripRefs: v3.TripReference[]): Model.TripReference[] => tripRefs as Model.Trip[],

  convertCompanion: (companion: v3.Companion): Model.Companion => companion as Model.Companion,

  convertCompanions: (companions: v3.Companion[]): Model.Companion[] => companions as Model.Companion[],
};

export default convert;
