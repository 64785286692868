const random = {
  /**
   * random object in array
   * @param {array} any array
   * @returns {object} random array item
   */
  random(array: Array<any>): object {
    return array[Math.floor(array.length * Math.random())];
  },

  /**
   * random coordinate on the world
   * @returns {object} random coordinate on the world { lat: [(-90)-(90)], lng: [(-180)-(180)] }
   */
  randomCoordinate(): object {
    return {
      lat: (Math.floor(Math.random() * 90000000) / 1000000) * (Math.random() < 0.5 ? 1 : -1),
      lng: (Math.floor(Math.random() * 180000000) / 1000000) * (Math.random() < 0.5 ? 1 : -1),
    };
  },

  /**
   * random adult count
   * @returns {number} random adult count between 1-12
   */
  randomAdultCount(): number {
    return 1 + Math.floor(Math.random() * 12);
  },

  /**
   * random children count
   * @returns {number} random children count between 0-12
   */
  randomChildrenCount(): number {
    return Math.floor(Math.random() * 12);
  },

  /**
   * random adult age
   * @returns {number} random adult count age 18-115
   */
  randomAdultAge(): number {
    return 18 + Math.floor(Math.random() * 97);
  },

  /**
   * random children age
   * @returns {number} random children count age 0-17
   */
  randomChildAge(): number {
    return Math.floor(Math.random() * 17);
  },

  /**
   * random answers array
   * @returns {array} random sub array of [5, 6, 16, 18, 27, 35, 12, 13, 25, 32, 33, 34, 1, 2, 41, 9, 10, 22, 40]
   */
  randomAnswers(): Array<number> {
    const allAnswers = [5, 6, 16, 18, 27, 35, 12, 13, 25, 32, 33, 34, 1, 2, 41, 9, 10, 22, 40];
    const count = Math.floor(allAnswers.length - 1 * Math.random()) + 1;
    const answers: Array<number> = [];
    for (let i = 0; i < count; i += 1) {
      const answer = allAnswers[Math.floor(allAnswers.length * Math.random())];
      if (!answers.includes(answer)) answers.push(answer);
    }
    return answers;
  },

  /**
   * random username
   * @returns {string} random username start with `Uu` and length is 9 chars
   */
  randomUsername(): string {
    return `Uu${Math.random()
      .toString(36)
      .substring(7)}`;
  },

  /**
   * add days param times day to date
   * @param {Date} increase this date
   * @param {number} number days for increase
   * @return {Date} increased date
   */
  addDays(date: Date, days: number): Date {
    return new Date(date.valueOf() + 864e5 * days);
  },

  /**
   * If chars's length is 2 return chars, if chars's length is 1 return `0${chars}`
   * @param {string} chars any string
   * @return {string} 2 chars string
   */
  extend2Chars(chars: string): string {
    return chars.toString().length === 2 ? chars.toString() : `0${chars.toString()}`;
  },

  /**
   * random date range
   * @returns {object} random date range for create a trip { arrivalDate: YYYY-MM-DD, departureDate: YYYY-MM-DD } Maximum range is 14.
   */
  randomDateRange(): object {
    const randomCount = 1 + Math.floor(Math.random() * 30);
    let arrivalDate = new Date();
    arrivalDate = this.addDays(arrivalDate, randomCount);
    let departureDate = new Date();
    departureDate = this.addDays(departureDate, randomCount + 1 + Math.floor(Math.random() * 13));
    return {
      arrivalDate: `${arrivalDate.getFullYear()}-${this.extend2Chars((arrivalDate.getMonth() + 1).toString())}-${this.extend2Chars(
        arrivalDate.getDate().toString(),
      )}`,
      departureDate: `${departureDate.getFullYear()}-${this.extend2Chars((departureDate.getMonth() + 1).toString())}-${this.extend2Chars(
        departureDate.getDate().toString(),
      )}`,
    };
  },

  /**
   * random time
   * @returns {string} random time between `00:00` and `23:59`
   */
  randomTime(): string {
    return `${this.extend2Chars(Math.floor(Math.random() * 24).toString())}:00`;
  },

  /**
   * random time range
   * @returns {object} random time range for create a trip { startTime: hh:mm, endTime: hh:mm }
   */
  randomTimeRange() {
    const startTime = Math.floor(Math.random() * 12);
    const endTime = 12 + Math.floor(Math.random() * 12);
    return { startTime: `${this.extend2Chars(startTime.toString())}:00`, endTime: `${this.extend2Chars(endTime.toString())}:00` };
  },

  /**
   * random ordered array
   * @param {array} array
   * @returns {array} random ordered array of param array items.
   */
  randomOrder(array: Array<any>): Array<any> {
    const exOrder = [...array];
    const newOrder = [];
    while (exOrder.length !== 0) {
      const i = Math.floor(Math.random() * exOrder.length);
      newOrder.push(exOrder[i]);
      exOrder.splice(i, 1);
    }
    return newOrder;
  },
};

export default random;
