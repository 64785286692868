import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { UserUpdateForm } from '@tripian/react';
import { api } from '@tripian/core';
import Model from '@tripian/model';
import { useHistory } from 'react-router';
import ICombinedState from '../../redux/model/ICombinedState';

import { USER_PROFILE as USER_PROFILE_PATH_TITLE } from '../../constants/ROUTER_PATH_TITLE';
import { successUser } from '../../redux/action/auth';

interface IUserProfile {
  title: string;
}

const UserProfile: React.FC<IUserProfile> = ({ title }) => {
  const [profileQuestions, setProfileQUestions] = useState<Model.Question[]>([]);
  const [loading, setLoading] = useState(true);
  const user = useSelector((state: ICombinedState) => state.auth.user);
  const dispatch = useDispatch();
  const history = useHistory();

  document.title = `${USER_PROFILE_PATH_TITLE.TITLE} - ${title}`;

  useEffect(() => {
    let unmounted = false;

    api.questionsProfile().then((profileQuestionsParam) => {
      if (!unmounted) {
        setProfileQUestions(profileQuestionsParam);
        setLoading(false);
      }
    });

    return () => {
      unmounted = true;
    };
  }, []);

  const updateCallback = (updatedUser: Model.User) => {
    dispatch(successUser(updatedUser));
  };

  const update = (value: Model.UserUpdateRequest) => {
    return api.userUpdate(value);
  };

  if (loading) {
    return <div className="center mt12">Loading...</div>;
  }

  if (user)
    return (
      <div className="container mt10">
        <div className="row center">
          <UserUpdateForm
            user={user}
            profileQuestions={profileQuestions}
            updateUser={update}
            updateCallback={updateCallback}
            cancel={() => {
              history.goBack();
            }}
          />
        </div>
      </div>
    );

  return null;
};

export default UserProfile;
