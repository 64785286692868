import { combineReducers } from 'redux';
// import ICombinedState from '../model/ICombinedState';
import layout from './layout';
import auth from './auth';
import gmap from './gmap';
import trip from './trip';

const rootReducer = combineReducers({ layout, auth, gmap, trip });

export default rootReducer;
// export type RootState = ReturnType<typeof rootReducer>;
