// eslint-disable-next-line import/no-extraneous-dependencies
import Model from '@tripian/model';
// eslint-disable-next-line import/no-unresolved
import { ILeg } from '@tripian/react/components/GoogleMaps/GRoute/IRouteResult';
import IAction from '../model/IAction';
import * as GMAPS_ACTIONS from '../actionType/gmap';

export const changeCenter = (latlng: Model.Coordinate): IAction => ({
  type: GMAPS_ACTIONS.CHANGE_CENTER,
  payload: { latlng },
});

export const changeZoom = (zoom: number): IAction => ({
  type: GMAPS_ACTIONS.CHANGE_ZOOM,
  payload: { zoom },
});

export const changeBoundry = (boundry: Array<number>): IAction => ({
  type: GMAPS_ACTIONS.CHANGE_BOUNDRY,
  payload: { boundry },
});

export const changeLegs = (legs?: ILeg[]): IAction => ({
  type: GMAPS_ACTIONS.CHANGE_LEGS,
  payload: { legs },
});

export const handleMapClick = (coordinate: Model.Coordinate): IAction => ({
  type: GMAPS_ACTIONS.HANDLE_MAP_CLICK,
  payload: { coordinate },
});

export const handleClickMarkerStep = (step: Model.Step): IAction => ({
  type: GMAPS_ACTIONS.HANDLE_MARKER_CLICK_STEP,
  payload: { step },
});

export const handleFocusChangeStep = (step: Model.Step): IAction => ({
  type: GMAPS_ACTIONS.HANDLE_FOCUS_CHANGE_STEP,
  payload: { focusStep: step },
});

export const handleFocusChangePoi = (poi: Model.Poi): IAction => ({
  type: GMAPS_ACTIONS.HANDLE_FOCUS_CHANGE_POI,
  payload: { focusPoi: poi },
});
