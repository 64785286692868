// eslint-disable-next-line import/no-extraneous-dependencies
import React from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import Model from '@tripian/model';
// eslint-disable-next-line import/no-extraneous-dependencies
import { StepInfo, PoiInfo } from '@tripian/react';
import { useDispatch } from 'react-redux';

import { handleMapClick } from '../../../../redux/action/gmaps';
import { stepAdd, /* stepReplace, */ stepDelete } from '../../../../redux/action/trip';
import { changeFocusInfoVisible } from '../../../../redux/action/layout';

import './FocusContainer.scss';

interface IFocusContainer {
  show: boolean;
  planId: number;
  focus?: { step?: Model.Step; poi?: Model.Poi };
}

const FocusContainer: React.FC<IFocusContainer> = ({ show, planId, focus }) => {
  const dispatch = useDispatch();

  const focusContainerClasses = ['focusContainer'];
  if (show) focusContainerClasses.push('focusContainerOpen');
  else focusContainerClasses.push('focusContainerClose');

  const renderContent = () => {
    if (focus?.step)
      return (
        <div className="scrollable-y">
          <StepInfo
            key={`focus_poi_${focus.step.id}`}
            step={focus?.step}
            favorite={false}
            toggleFavorite={(poi: Model.Poi) => {
              // eslint-disable-next-line no-console
              console.log(`${poi.name} added to favorites..`);
            }}
            close={() => {
              dispatch(changeFocusInfoVisible(false));
              dispatch(handleMapClick({ lat: 0, lng: 0 }));
            }}
            removeStep={(stepId) => {
              dispatch(changeFocusInfoVisible(false));
              dispatch(stepDelete(stepId));
            }}
          />
        </div>
      );

    if (focus?.poi)
      return (
        <div className="scrollable-y">
          <PoiInfo
            key={`focus_poi_${focus.poi.id}`}
            poi={focus.poi}
            favorite={false}
            toggleFavorite={(poi: Model.Poi) => {
              // eslint-disable-next-line no-console
              console.log(`${poi.name} added to favorites..`);
            }}
            close={() => {
              dispatch(changeFocusInfoVisible(false));
              dispatch(handleMapClick({ lat: 0, lng: 0 }));
            }}
            addRemovePoi={(poi: Model.Poi, willAdd: boolean) => {
              // eslint-disable-next-line no-console
              console.log('PoiInfo addRemovePoi', poi, willAdd);
              dispatch(changeFocusInfoVisible(false));
              if (willAdd) {
                dispatch(stepAdd(planId, poi.id));
              } else {
                // PoiInfo never call this props when we use directly StepInfo component.
                // Only StepInfo component handle this event with StepInfo.removeStep
                // eslint-disable-next-line no-console
                console.error(
                  'PoiInfo never call this props when we use directly StepInfo component. Only StepInfo component handle this event with StepInfo.removeStep',
                );
                // dispatch(stepDelete(12));
              }
            }}
            // TODO
            /* replacePoi={(poi: Model.Poi) => {
              console.log('PoiInfo replacePoi', poi);
            }} */
          />
        </div>
      );

    return null;
  };

  return <div className={focusContainerClasses.join(' ')}>{renderContent()}</div>;
};

export default FocusContainer;
