/* eslint-disable no-console */
// eslint-disable-next-line import/no-extraneous-dependencies
import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';

// eslint-disable-next-line import/no-extraneous-dependencies
import { Loading } from '@tripian/react';
import ICombinedState from '../../redux/model/ICombinedState';
// import { changeTripListVisible } from '../../redux/action/layout'; */
import { changeHash, fetchTrip, fetchAlternatives } from '../../redux/action/trip';

import './Trip.scss';

import MapLayout from './layouts/Map/MapLayout';
import Menu from './layouts/Menu/Menu';
import PlanContainer from './layouts/Container/PlanContainer';
import FocusContainer from './layouts/Container/FocusContainer';

import { TRIP } from '../../constants/ROUTER_PATH_TITLE';
import { changeTripListVisible } from '../../redux/action/layout';

interface ITripPage {
  title: string;
}

const mapClasses = ['map-container'];
const TripPage: React.FC<ITripPage> = ({ title }) => {
  const [loading, setLoading] = useState(true);
  const { showPlanList, showFocusInfo, tripData, focus, day } = useSelector((state: ICombinedState) => ({
    showPlanList: state.layout.tripListVisible,
    showFocusInfo: state.layout.focusInfoVisible,
    tripData: state.trip.data,
    focus: state.gmap.focus,
    day: state.trip.day,
  }));
  const { hash } = useParams<{ hash: string }>();
  const dispatch = useDispatch();

  document.title = `${TRIP.TITLE} - ${hash} - ${title}`;

  useEffect(() => {
    dispatch(changeHash(hash));
    dispatch(fetchTrip(hash));
    setLoading(false);
  }, [dispatch, hash]);

  useEffect(() => {
    if (tripData) {
      tripData.plans[day].steps.forEach((step, index) => {
        dispatch(fetchAlternatives(index, step.alternatives));
      });
    }
  }, [dispatch, tripData, day]);

  if (!showPlanList) mapClasses.push('w-m-100');

  if (!tripData || loading) {
    return <Loading />;
  }

  return (
    <>
      <MapLayout fullWidth={!showPlanList} />
      <PlanContainer show={showPlanList} />
      <FocusContainer show={showFocusInfo} planId={tripData.plans[day].id} focus={focus} />
      <Menu
        listShown={showPlanList}
        showList={() => {
          dispatch(changeTripListVisible(!showPlanList));
        }}
      />
    </>
  );
};

export default TripPage;
