// eslint-disable-next-line import/no-extraneous-dependencies
import React from 'react';
import AppRouter from './AppRouter/AppRouter';

import './App.css';

const App = () => {
  return (
    <div className="App">
      <AppRouter />
    </div>
  );
};

export default App;
