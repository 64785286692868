/*
 * action types
 */

export const CHANGE_ZOOM: string = 'CHANGE_ZOOM';
export const CHANGE_CENTER: string = 'SET_CENTER';
export const CHANGE_BOUNDRY: string = 'CHANGE_BOUNDRY';
export const HANDLE_MAP_CLICK: string = 'HANDLE_MAP_CLICK';
export const HANDLE_MARKER_CLICK_STEP: string = 'HANDLE_MARKER_CLICK_STEP';
export const CHANGE_LEGS: string = 'CHANGE_LEGS';
export const HANDLE_FOCUS_CHANGE_POI: string = 'HANDLE_FOCUS_CHANGE_POI';
export const HANDLE_FOCUS_CHANGE_STEP: string = 'HANDLE_FOCUS_CHANGE_STEP';
