import { AxiosError } from 'axios';

import data from '../../data/data';
import cache from './cache/cache';

interface SuccessResponse<T> {
  status: number;
  success: boolean;
  message: string;
  data: T;
}

/**
 * All successfully (axios response status code) resolved promises firstly handles by this function. easy.log calling.
 * If dataKey exist and httpResponse.data.success is true, httpResponse.data.data is saving  to tripian.data[dataKey]
 * @param {AxiosResponse} httpResponse axios response object
 * @param {string} dataKey
 * @returns {any} httpResponse.data axios httpResponse.data
 */
const handleHttpResponseSuccess = <T = any>(httpResponse: SuccessResponse<T>, dataKey?: string) => {
  // easy.log(JSON.parse(`${dataKey} dataKey saving..${httpResponse}`));
  if (dataKey) {
    if (httpResponse.success) {
      cache<T>(httpResponse.data, dataKey);
    } else {
      throw httpResponse.message;
    }
  }
  return httpResponse.data;
};

/**
 * All failed (axios response status code) promises firstly handles by this function.
 * setting tripian.data.hasError to true
 *  easy.log calling.
 * If dataKey exist (and dataKey !== 'trip') and httpResponse.data.success is true, httpResponse.data.data is saving  to tripian.data[dataKey]
 * @param {AxiosError} errorResponse is axios error response object
 * @param {string} dataKey
 * @returns {AxiosError} errorResponse is axios error response object
 */
const handleHttpResponseError = (errorResponse: AxiosError, dataKey?: string) => {
  data.hasError = true;
  data.errors.push({ dataKey, errorResponse });
  if (dataKey) {
    if (dataKey !== 'trip') {
      (data as any)[dataKey] = null;
    }
  }

  if (errorResponse.response) {
    if (errorResponse.response.data) {
      if (errorResponse.response.data.message) throw errorResponse.response.data.message;
      throw errorResponse.response.data;
    }
    throw errorResponse.response;
  }
  throw errorResponse;
};

export { handleHttpResponseSuccess, handleHttpResponseError };
