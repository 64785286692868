import Model from '@tripian/model';

import XHR from '../xhr/xhr';
import API_CONST from './const/APICONST';
import IXhrOptions from '../xhr/IXhrOptions';

import easy from '../easy/easy';
// import cache from '../easy/handle/cache/cache';
import Cached from '../easy/handle/cache/Cached';
import { allQuestionsData } from '../easy/handle/cache/cacheCommon';

import data from '../data/data';
import dataClear from '../data/dataClear';

const sleep = async (milisecond: number): Promise<boolean> => {
  // console.log(milisecond, 'sn uyumaya geldik');
  return new Promise((resolve) => setTimeout(() => resolve(true), milisecond));
};

interface IAPIpoisNameSearchParams {
  city_id?: number;
  coordinate?: string;
  distance?: number;
  page?: number;
  poi_ids?: string;
  poi_categories?: string;
  search?: string;
  limit?: number;
}

interface IpoisNameSearchParams {
  cityId?: number;
  name: string;
  poiCategories?: number[];
  limit?: number;
}

interface IpoisCoordinateSearchParams {
  coordinate: Model.Coordinate;
  distance?: number;
  poiCategories?: number[];
  limit: number;
}

class API {
  private xhr: XHR;

  constructor(apiSettings: IXhrOptions) {
    this.xhr = XHR.getInstance(apiSettings);
  }

  getToken = () => this.xhr.getToken();

  setToken = (token: Model.Token) => this.xhr.setToken(token);

  removeToken = () => this.xhr.removeToken();

  /**
   ******************************************************************************
   *
   * Cities
   *
   */
  /*   cities = async (limit: number = 300) => {
    const cachedCities = Cached.cities();
    if (cachedCities) return Promise.resolve(cachedCities);

    return this.xhr.req(API_CONST.CITIES.METHOD)<Model.City[]>(API_CONST.CITIES.PATH, API_CONST.CITIES.DATA_KEY, { limit });
  }; */

  citiesPage = async (page: number, limit: number = 100) => {
    const cachedCities = Cached.cities();
    if (cachedCities) return Promise.resolve(cachedCities);

    return this.xhr.req(API_CONST.CITIES.METHOD)<Model.City[]>(API_CONST.CITIES.PATH, API_CONST.CITIES.DATA_KEY, { limit, page });
  };

  citiesAll = async () => {
    const cachedCities = Cached.cities();
    if (cachedCities) return Promise.resolve(cachedCities);

    // TEMP
    const citiesPromise1 = this.citiesPage(1);
    const citiesPromise2 = this.citiesPage(2);
    const citiesPromise3 = this.citiesPage(3);

    // TODO for page number
    return Promise.all([citiesPromise1, citiesPromise2, citiesPromise3]).then(([cities1, cities2, cities3]) =>
      cities1.concat(cities2.concat(cities3)),
    );
  };

  // citiesSearch = async (cityName: string) =>
  //   this.xhr.req(API_CONST.CITIES_SEARCH.METHOD)<Model.City[]>(
  //     easy.setParameter(API_CONST.CITIES_SEARCH.PATH, { key: 'cityName', value: cityName }),
  //     API_CONST.CITIES_SEARCH.DATA_KEY,
  //   );

  // city = async (cityId: number) => {
  //   const cachedCity = Cached.city(cityId);
  //   if (cachedCity) {
  //     cache<Model.City>(cachedCity, API_CONST.CITY.DATA_KEY || 'city');
  //     return Promise.resolve<Model.City>(cachedCity);
  //   }
  //   return this.xhr.req(API_CONST.CITY.METHOD)<Model.City>(easy.setParameterId(API_CONST.CITY.PATH, cityId), API_CONST.CITY.DATA_KEY);
  // };
  // cityFind = async ({ lat, lng }: Model.Coordinate) =>
  //   this.xhr.req(API_CONST.CITY_FIND.METHOD)<Model.City>(API_CONST.CITY_FIND.PATH, API_CONST.CITY_FIND.DATA_KEY, { coordinate: `${lat},${lng}` });

  /**
   * POI
   */
  poiCategories = async (limit: number = 100): Promise<Model.PoiCategory[]> => {
    const poiCategories = Cached.poiCategories();
    if (poiCategories) return poiCategories;

    return this.xhr.req(API_CONST.POI_CATEGORIES.METHOD)<Model.PoiCategory[]>(API_CONST.POI_CATEGORIES.PATH, API_CONST.POI_CATEGORIES.DATA_KEY, {
      limit,
    });
  };

  private poisSearch = async (searchParams: IAPIpoisNameSearchParams): Promise<Model.Poi[]> =>
    this.xhr.req(API_CONST.POIS.METHOD)<Model.Poi[]>(API_CONST.POIS.PATH, API_CONST.POIS.DATA_KEY, searchParams);

  poisNameSearch = async ({ cityId, name, poiCategories, limit = 100 }: IpoisNameSearchParams): Promise<Model.Poi[]> =>
    this.poisSearch({
      city_id: cityId,
      search: name,
      poi_categories: poiCategories && poiCategories.length > 0 ? poiCategories.join(',') : undefined,
      limit: limit > 100 ? limit : limit,
    });

  poisCoordinateSearch = async ({ coordinate, distance, poiCategories, limit }: IpoisCoordinateSearchParams): Promise<Model.Poi[]> =>
    this.poisSearch({ coordinate: `${coordinate.lat},${coordinate.lng}`, distance, poi_categories: poiCategories?.join(','), limit });

  pois = async (poiIds: number[]): Promise<Model.Poi[]> => {
    const cachedPois = Cached.pois(poiIds);
    if (cachedPois) return Promise.resolve(cachedPois);

    return this.poisSearch({ poi_ids: poiIds.join(',') });
  };

  poi = async (poiId: number): Promise<Model.Poi> => {
    const cachedPoi = Cached.poi(poiId);
    if (cachedPoi) return Promise.resolve(cachedPoi);

    return this.xhr.req(API_CONST.POI.METHOD)<Model.Poi>(easy.setParameterId(API_CONST.POI.PATH, poiId), API_CONST.POI.DATA_KEY);
  };

  /**
   * Questions
   */
  private questions = async (category?: string, languageCode: string = 'en'): Promise<Model.Question[]> => {
    const dataKey = category == null ? `${API_CONST.QUESTIONS.DATA_KEY}Trip` : API_CONST.QUESTIONS.DATA_KEY + easy.capitalizeFirstLetter(category);
    const cachedQuestions = Cached.questions(dataKey);
    if (cachedQuestions) return Promise.resolve(cachedQuestions);

    return this.xhr
      .req(API_CONST.QUESTIONS.METHOD)<Model.Question[]>(API_CONST.QUESTIONS.PATH, dataKey, {
        category,
        language_code: languageCode, // (en,fr)
      })
      .then((questionsResponse) => {
        allQuestionsData();
        return questionsResponse;
      });
  };

  questionsTrip = async (languageCode: string = 'en'): Promise<Model.Question[]> => this.questions('trip', languageCode);

  questionsProfile = async (languageCode: string = 'en'): Promise<Model.Question[]> => this.questions('profile', languageCode);

  questionsCompanion = async (languageCode: string = 'en'): Promise<Model.Question[]> => this.questions('companion', languageCode);

  questionsAll = async (languageCode: string = 'en'): Promise<Model.Question[]> => {
    const questionsTripPromise = this.questionsTrip(languageCode);
    const questionsProfilePromise = this.questionsProfile(languageCode);
    const questionsCompanionPromise = this.questionsCompanion(languageCode);
    return Promise.all([questionsTripPromise, questionsProfilePromise, questionsCompanionPromise]).then((qAllArray) => {
      const qAll: Model.Question[] = [];
      return qAll.concat(...qAllArray);
    });
  };

  /**
   * Recommendations
   */
  // recommendations = async ({
  //   hash,
  //   cityId,
  //   answers,
  //   poiCategories,
  //   adults,
  //   children,
  //   coordinate,
  //   adultAgeAverage,
  //   childrenAgeAverage,
  // }: {
  //   hash: string;
  //   cityId: number;
  //   answers: number[];
  //   poiCategories: POICategory[];
  //   adults: number;
  //   children: number;
  //   coordinate: Coordinate;
  //   adultAgeAverage: number;
  //   childrenAgeAverage: number;
  // }) =>
  //   this.xhr.req(API_CONST.RECOMMENDATIONS.METHOD)<Recommendation[]>(API_CONST.RECOMMENDATIONS.PATH, API_CONST.RECOMMENDATIONS.DATA_KEY, {
  //     hash,
  //     city_id: cityId,
  //     answers: answers && answers.length !== 0 ? answers.join(',') : null,
  //     poi_categories: poiCategories && poiCategories.length !== 0 ? poiCategories.join(',') : null,
  //     adults: adults || 1,
  //     children: children || 0,
  //     coordinate: coordinate && coordinate.lat && coordinate.lng ? `${coordinate.lat},${coordinate.lng}` : null,
  //     adult_age_average: adultAgeAverage,
  //     children_age_average: childrenAgeAverage,
  //   });

  /**
   * User
   */
  register = async (registerRequest: Model.RegisterRequest) => {
    /* const registerParams: any = {};
    if (username) {
      registerParams.username = username;
    } else if (email && password) {
      registerParams.email = email;
      registerParams.password = password;
      registerParams.first_name = firstName;
      registerParams.last_name = lastName;
    } */
    return this.xhr.req(API_CONST.REGISTER.METHOD)<Model.User>(API_CONST.REGISTER.PATH, API_CONST.REGISTER.DATA_KEY, registerRequest);
  };

  private login = async (
    loginRequestEmail?: Model.LoginRequestEmail,
    loginRequestUsername?: Model.LoginRequestUsername,
    loginRequestTripHash?: Model.LoginRequestTripHash,
  ) => {
    let loginRequest = null;

    if (loginRequestEmail) {
      loginRequest = loginRequestEmail;
    } else if (loginRequestUsername) {
      loginRequest = loginRequestUsername;
    } else if (loginRequestTripHash) {
      loginRequest = loginRequestTripHash;
    }

    return this.xhr.req(API_CONST.LOGIN.METHOD)<Model.Token>(API_CONST.LOGIN.PATH, API_CONST.LOGIN.DATA_KEY, loginRequest);
  };

  loginEmail = async (loginRequestEmail?: Model.LoginRequestEmail) => this.login(loginRequestEmail);

  loginUsername = async (loginRequestUsername?: Model.LoginRequestUsername) => this.login(undefined, loginRequestUsername);

  loginTripHash = async (loginRequestTripHash?: Model.LoginRequestTripHash) => this.login(undefined, undefined, loginRequestTripHash);

  refreshToken = async () => {
    const currentToken = this.xhr.getToken();

    if (currentToken) {
      /**
       * xhr has token object
       */
      const tokenPayload = easy.parseToken(currentToken);
      if (tokenPayload) {
        /**
         * xhr token object is valid
         */
        const accessTokenExpireSeconds = easy.accessTokenExpSecond(tokenPayload);
        if (accessTokenExpireSeconds > 60) {
          /**
           * access_token is still valid
           * returned currentToken.
           */
          return new Promise<Model.Token>((resolve) => resolve(currentToken));
        }

        /**
         * access_token expired or expire impending
         */
        const refreshToken = currentToken.refresh_token;
        const refreshTokenExpireSeconds = easy.refreshTokenExpSecond(tokenPayload);

        if (refreshTokenExpireSeconds > 3600) {
          /**
           * refresh_token is still valid
           * we will refresh access_token here.
           */
          return this.xhr
            .req(API_CONST.REFRESH_TOKEN.METHOD)<Model.Token>(API_CONST.REFRESH_TOKEN.PATH, API_CONST.REFRESH_TOKEN.DATA_KEY, {
              refresh_token: refreshToken,
            })
            .then((token) => {
              const fullToken: Model.Token = { ...token, refresh_token: refreshToken };
              this.setToken(fullToken);
              return fullToken;
            });
        }

        /**
         * refresh_token expired or expire impending
         */
        // console.log('Tcore refresh token expired');
        throw new Error('Tcore refresh token expired');
      }

      /**
       * xhr token object is invalid
       */
      // console.log("Tcore doesn't have a valid token object"); // eslint-disable-line quotes
      throw new Error("Tcore doesn't have a valid token object"); // eslint-disable-line quotes
    }

    /**
     * xhr has not token object
     */
    // eslint-disable-next-line quotes
    // console.log("Tcore doesn't have a token object"); // eslint-disable-line quotes
    throw new Error("Tcore doesn't have a token object"); // eslint-disable-line quotes
  };

  user = async (force = false) => {
    if (!force) {
      const cachedUser = Cached.user();
      if (cachedUser) return Promise.resolve(cachedUser);
    }

    return this.xhr.req(API_CONST.USER.METHOD)<Model.User>(API_CONST.USER.PATH, API_CONST.USER.DATA_KEY);
  };

  userUpdate = async (userUpdateRequest: Model.UserUpdateRequest) => {
    return this.xhr.req(API_CONST.USER_UPDATE.METHOD)<Model.User>(API_CONST.USER_UPDATE.PATH, API_CONST.USER_UPDATE.DATA_KEY, userUpdateRequest);
  };

  /**
   * Favorites
   */
  // favorites = async ({ cityId, hash }: { cityId: number; hash: string }) =>
  //   this.xhr.req(API_CONST.FAVORITES.METHOD)<Favorite[]>(API_CONST.FAVORITES.PATH, API_CONST.FAVORITES.DATA_KEY, { city_id: cityId, hash });

  // favoriteAdd = async ({ cityId, poiId, hash }: { cityId: number; poiId: number; hash: string }) =>
  //   this.xhr
  //     .req(API_CONST.FAVORITE_ADD.METHOD)(API_CONST.FAVORITE_ADD.PATH, undefined, { city_id: cityId, poi_id: poiId, hash })
  //     .then(() =>
  //       this.xhr.req(API_CONST.FAVORITE_ADD.CALL_BACK?.METHOD || 'GET')(
  //         API_CONST.FAVORITE_ADD.CALL_BACK?.PATH || '/user/favorites',
  //         API_CONST.FAVORITE_ADD.CALL_BACK?.DATA_KEY || 'favorites',
  //       ),
  //     );

  // favoriteDelete = async ({ cityId, poiId, hash }: { cityId: number; poiId: number; hash: string }) =>
  //   this.xhr
  //     .req(API_CONST.FAVORITE_DELETE.METHOD)(API_CONST.FAVORITE_DELETE.PATH, undefined, { city_id: cityId, poi_id: poiId, hash })
  //     .then(() =>
  //       this.xhr.req(API_CONST.FAVORITE_DELETE.CALL_BACK?.METHOD || 'GET')(
  //         API_CONST.FAVORITE_DELETE.CALL_BACK?.PATH || '/user/favorites',
  //         API_CONST.FAVORITE_DELETE.CALL_BACK?.DATA_KEY || 'favorites',
  //       ),
  //     );

  /**
   * Companions (TODO)
   */
  companions = async (): Promise<Model.Companion[]> =>
    this.xhr.req(API_CONST.COMPANIONS.METHOD)<Model.Companion[]>(API_CONST.COMPANIONS.PATH, API_CONST.COMPANIONS.DATA_KEY);

  companionAdd = async (companion: Model.Companion): Promise<Model.Companion> =>
    this.xhr.req(API_CONST.COMPANION_ADD.METHOD)<Model.Companion>(API_CONST.COMPANION_ADD.PATH, API_CONST.COMPANION_ADD.DATA_KEY, companion);

  companionUpdate = async (companion: Model.Companion): Promise<Model.Companion> =>
    this.xhr.req(API_CONST.COMPANION_UPDATE.METHOD)<Model.Companion>(
      easy.setParameterId(API_CONST.COMPANION_UPDATE.PATH, companion.id),
      API_CONST.COMPANION_UPDATE.DATA_KEY,
      companion,
    );

  companionDelete = async (companionId: number): Promise<any> =>
    this.xhr.req(API_CONST.COMPANION_DELETE.METHOD)<any>(easy.setParameterId(API_CONST.COMPANION_DELETE.PATH, companionId));

  /**
   * Trips
   */
  trips = async (): Promise<Model.TripReference[]> => {
    const cachedTripRefs = Cached.tripRefs();
    if (cachedTripRefs) return Promise.resolve(cachedTripRefs);
    return this.xhr.req(API_CONST.TRIPS.METHOD)<Model.TripReference[]>(API_CONST.TRIPS.PATH, API_CONST.TRIPS.DATA_KEY);
  };

  trip = async (hash: string, minDayIndex: number = 0, force: boolean = false): Promise<Model.Trip> => {
    if (!force) {
      const cachedTrip = Cached.trip(hash, minDayIndex);
      if (cachedTrip) {
        data.trip = cachedTrip;
        return Promise.resolve(cachedTrip);
      }
    }
    return this.xhr
      .req(API_CONST.TRIP.METHOD)<Model.Trip>(easy.setParameter(API_CONST.TRIP.PATH, { key: 'hash', value: hash }), API_CONST.TRIP.DATA_KEY)
      .then(async (tripData) => {
        const minPlanIndex = tripData.plans.length - 1 < minDayIndex ? tripData.plans.length - 1 : minDayIndex;
        if (tripData.plans[minPlanIndex].generated_status === 0) {
          const result = await sleep(200);
          if (result) {
            return this.trip(tripData.trip_hash, minPlanIndex);
          }
        }
        return tripData;
      });
  };

  tripAdd = async (tripProfile: Model.TripProfile, minDayIndex: number = 0) =>
    this.xhr
      .req(API_CONST.TRIP_ADD.METHOD)<Model.Trip>(API_CONST.TRIP_ADD.PATH, API_CONST.TRIP_ADD.DATA_KEY, tripProfile)
      .then(async (tripData) => {
        // console.log('tripAdd', minDayIndex, '. plan için bekliyorduk.');
        // data.trips.push(tripData);
        const minPlanIndex = tripData.plans.length - 1 < minDayIndex ? tripData.plans.length - 1 : minDayIndex;
        // console.log('tripAdd', minPlanIndex, '. plan için bekliyoruk.');
        if (tripData.plans[minPlanIndex].generated_status === 0) {
          // console.log('tripAdd', minPlanIndex, '. plan daha olmamış.');
          const result = await sleep(200);
          // console.log('tripAdd', result, ' şimdi uyandık');
          if (result) {
            // console.log('tripAdd', ' bi daha');
            return this.trip(tripData.trip_hash, minPlanIndex);
          }
        }
        // console.log('tripAdd', minPlanIndex, '. plan tekte olmuş al.');
        return tripData;
      });

  tripUpdate = async (tripHash: string, tripProfile: Model.TripProfile, minDayIndex: number = 0) => {
    return this.xhr
      .req(API_CONST.TRIP_UPDATE.METHOD)<Model.Trip>(
        easy.setParameter(API_CONST.TRIP_UPDATE.PATH, { key: 'hash', value: tripHash }),
        API_CONST.TRIP_UPDATE.DATA_KEY,
        tripProfile,
      )
      .then(async (tripData) => {
        // console.log('tripUpdate', minDayIndex, '. plan için bekliyorduk.');
        // data.trips.push(tripData);
        const minPlanIndex = tripData.plans.length - 1 < minDayIndex ? tripData.plans.length - 1 : minDayIndex;
        // console.log('tripUpdate', minPlanIndex, '. plan için bekliyoruk.');
        if (tripData.plans[minPlanIndex].generated_status === 0) {
          // console.log('tripUpdate', minPlanIndex, '. plan daha olmamış.');
          const result = await sleep(200);
          // console.log('tripUpdate', result, ' şimdi uyandık');
          if (result) {
            // console.log('tripUpdate', ' bi daha');
            return this.trip(tripData.trip_hash, minPlanIndex);
          }
        }
        // console.log('tripUpdate', minPlanIndex, '. plan tekte olmuş al.');
        return tripData;
      });
  };

  tripDelete = async (hash: string) => {
    return this.xhr
      .req(API_CONST.TRIP_DELETE.METHOD)<{ id: number }>(easy.setParameter(API_CONST.TRIP_DELETE.PATH, { key: 'hash', value: hash }))
      .then((deletedTripData) => {
        // data.tripRefs = data.tripRefs.filter((tripRef) => tripRef.trip_hash !== hash);
        data.tripRefs = data.tripRefs?.filter((tripRef) => tripRef.id !== deletedTripData.id);
        return deletedTripData.id;
      });
  };

  /**
   * Plan
   */
  // this.plan = (planId) => this.xhr.req(API_CONST.PLAN.METHOD)(easy.setParameterId(API_CONST.PLAN.PATH, planId), API_CONST.PLAN.DATA_KEY);

  private planUpdate = async (id: number, planUpdateRequest: Model.PlanUpdateRequest): Promise<Model.Plan> =>
    this.xhr.req(API_CONST.PLAN_UPDATE.METHOD)<Model.Plan>(
      easy.setParameterId(API_CONST.PLAN_UPDATE.PATH, id),
      API_CONST.PLAN_UPDATE.DATA_KEY,
      planUpdateRequest,
    );

  planUpdateOrders = async (planId: number, orders: number[]): Promise<Model.Plan> => this.planUpdate(planId, { orders: orders.join(',') });

  planUpdateTime = async (planId: number, startTime: string, endTime: string): Promise<Model.Plan> =>
    this.planUpdate(planId, { start_time: startTime, end_time: endTime });

  /**
   * Step
   */
  stepAdd = async (planId: number, poiId: number, order?: number): Promise<Model.Trip> => {
    const stepRequest: Model.StepRequest = { plan_id: planId, poi_id: poiId, order };
    return this.xhr
      .req(API_CONST.STEP_ADD.METHOD)(API_CONST.STEP_ADD.PATH, undefined, stepRequest)
      .then(() => this.trip(data.trip?.trip_hash || '', 0, true));
  };

  stepReplace = async (stepId: number, newPoiId: number, order?: number): Promise<Model.Trip> => {
    const stepUpdateRequest: Model.StepUpdateRequest = { poi_id: newPoiId, order };
    return this.xhr
      .req(API_CONST.STEP_REPLACE.METHOD)(easy.setParameterId(API_CONST.STEP_REPLACE.PATH, stepId), undefined, stepUpdateRequest)
      .then(() => this.trip(data.trip?.trip_hash || '', 0, true));
  };

  stepDelete = async (stepId: number): Promise<Model.Trip> => {
    return this.xhr
      .req(API_CONST.STEP_DELETE.METHOD)(easy.setParameterId(API_CONST.STEP_REPLACE.PATH, stepId), API_CONST.STEP_DELETE.DATA_KEY)
      .then(() => this.trip(data.trip?.trip_hash || '', 0));
  };

  /**
   * Alternatives (TODO)
   */
  // this.alternatives = (params) => this.xhr.req(API_CONST.ALTERNATIVES.METHOD)(API_CONST.ALTERNATIVES.PATH, API_CONST.ALTERNATIVES.DATA_KEY, params);
  // this.alternativesTrip = (hash) => this.alternatives({ hash });
  // this.alternativesPlan = (planId) => this.alternatives({ dailyplan_id: planId });
  // this.alternativesPlanPOI = (planPoiId) => this.alternatives({ dailyplanpoi_id: planPoiId });

  /**
   * Feedback
   */
  // feedback = ({ hash, rating, message }: { hash: string; rating: number; message: string }) =>
  //   this.xhr.req(API_CONST.FEEDBACK.METHOD)<any>(API_CONST.FEEDBACK.PATH, API_CONST.FEEDBACK.DATA_KEY, { hash, rating, message });

  // /**
  //  * Problem
  //  */
  // problemCategories = () =>
  //   this.xhr.req(API_CONST.PROBLEM_CATEGORIES.METHOD)<any>(API_CONST.PROBLEM_CATEGORIES.PATH, API_CONST.PROBLEM_CATEGORIES.DATA_KEY);

  // problemReport = ({ problemCategoryName, message, poiId }: { problemCategoryName: string; message: string; poiId: number }) =>
  //   this.xhr.req(API_CONST.PROBLEM_REPORT.METHOD)<any>(API_CONST.PROBLEM_REPORT.PATH, API_CONST.PROBLEM_REPORT.DATA_KEY, {
  //     problem_category: problemCategoryName,
  //     message,
  //     poi_id: poiId,
  //   });

  logout = () => {
    this.removeToken();
    dataClear();
  };
}

export default API;
