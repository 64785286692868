import Model from '@tripian/model';

/**
 *
 * Cities
 *
 */
export const cityCached = (cities: Model.City[], id: number) => {
  return cities.find((city) => city.id === id);
};
export const citiesConcat = (cities: Model.City[] | undefined, newCities: Model.City[], override = false): Model.City[] | undefined => {
  let citiesCopy: Model.City[] = [];

  if (!cities) {
    if (!override) return undefined;
  } else {
    citiesCopy = [...cities];
  }

  newCities.forEach((newCity) => {
    if (!cityCached(citiesCopy, newCity.id)) {
      citiesCopy.push(newCity);
    }
  });
  return citiesCopy;
};

/**
 *
 * POI
 *
 */
export const poiCached = (pois: Model.Poi[], id: number) => {
  return pois.find((p) => p.id === id);
};
export const poisConcat = (pois: Model.Poi[], newPois: Model.Poi[]): Model.Poi[] => {
  newPois.forEach((newPoi) => {
    if (!poiCached(pois, newPoi.id)) {
      pois.push(newPoi);
    }
  });
  return pois;
};

/**
 ******************************************************************************
 *
 * Questions
 *
 */
export const questionCached = (questions: Model.Question[], id: number) => {
  return questions.find((question) => question.id === id);
};

const questionsConcatOne = (questions: Model.Question[], newQuestion: Model.Question): Model.Question[] => {
  const questionsCopy = [...questions];

  if (!questionCached(questionsCopy, newQuestion.id)) {
    questionsCopy.push(newQuestion);
  }
  return questionsCopy;
};

export const questionsConcat = (questions: Model.Question[], newQuestions: Model.Question[]): Model.Question[] => {
  let questionsCopy = [...questions];

  newQuestions.forEach((newQuestion) => {
    questionsCopy = questionsConcatOne(questionsCopy, newQuestion);
  });
  return questionsCopy;
};

/**
 *
 * Trip Refs
 *
 */
export const tripRefCached = (tripRefs: Model.TripReference[], id: number) => {
  return tripRefs.find((tripRef) => tripRef.id === id);
};
export const tripRefsConcatOne = (
  tripRefs: Model.TripReference[] | undefined,
  newTripRef: Model.TripReference,
): Model.TripReference[] | undefined => {
  if (!tripRefs) return undefined;

  const tripRefsCopy = [...tripRefs];

  if (tripRefCached(tripRefsCopy, newTripRef.id)) {
    const tripRefIndex = tripRefsCopy.findIndex((tripRef) => tripRef.id === newTripRef.id);
    tripRefsCopy[tripRefIndex] = newTripRef;
  } else {
    tripRefsCopy.push(newTripRef);
  }

  return tripRefsCopy;
};

/**
 *
 * Trips
 *
 */
export const tripCached = (trips: Model.Trip[], id: number) => {
  return trips.find((trip) => trip.id === id);
};
export const tripsConcatOne = (trips: Model.Trip[] | undefined, newTrip: Model.Trip): Model.Trip[] => {
  if (!trips) return [newTrip];

  const tripsCopy = [...trips];

  if (tripCached(tripsCopy, newTrip.id)) {
    const tripIndex = tripsCopy.findIndex((trip) => trip.id === newTrip.id);
    tripsCopy[tripIndex] = newTrip;
  } else {
    tripsCopy.push(newTrip);
  }

  return tripsCopy;
};

/**
 *
 * Plan
 *
 */
export const planUpdated = (updatedPlan: Model.Plan, trip?: Model.Trip): Model.Trip | undefined => {
  if (!trip) return undefined;

  const tripCopy = { ...trip };

  const updatedPlanIndex = tripCopy.plans.findIndex((plan) => plan.id === updatedPlan.id);
  if (updatedPlanIndex > -1) {
    tripCopy.plans[updatedPlanIndex] = updatedPlan;
    return tripCopy;
  }

  return undefined;
};

/**
 *
 * Step
 *
 */
export const stepDeleted = (deletedStepId: number, trip?: Model.Trip): Model.Trip | undefined => {
  if (!trip) return undefined;

  const tripCopy = { ...trip };
  for (let i = 0; i < trip.plans.length; i += 1) {
    // TODO @Umut
    // recalculate orderIds performance issue (maybe)
    const newSteps = [];
    const filteredSteps = trip.plans[i].steps.filter((step) => step.id != deletedStepId); // eslint-disable-line eqeqeq
    for (let j = 0; j < filteredSteps.length; j += 1) {
      newSteps.push(filteredSteps[j]);
      newSteps[j].order = j;
    }
    tripCopy.plans[i].steps = newSteps;
  }

  return tripCopy;
};

/**
 *
 * Companions
 *
 */
export const companionCached = (companions: Model.Companion[], id: number) => {
  return companions.find((companion) => companion.id === id);
};
export const companionConcatOne = (companions: Model.Companion[] | undefined, newCompanion: Model.Companion): Model.Companion[] | undefined => {
  if (!companions) return undefined;

  const companionsCopy = [...companions];

  if (companionCached(companionsCopy, newCompanion.id)) {
    const companionIndex = companionsCopy.findIndex((companionCopy) => companionCopy.id === newCompanion.id);
    companionsCopy[companionIndex] = newCompanion;
  } else {
    companionsCopy.push(newCompanion);
  }

  return companionsCopy;
};
