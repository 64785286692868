// eslint-disable-next-line import/no-extraneous-dependencies
import React, { useEffect } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import Model from '@tripian/model';
import { api } from '@tripian/core';
import { LoginEmailPasswordForm } from '@tripian/react';

import ICombinedState from '../../redux/model/ICombinedState';
import { successLogin } from '../../redux/action/auth';

import { LOGIN } from '../../constants/ROUTER_PATH_TITLE';

const LoginPage = () => {
  const isLoggedIn = useSelector((state: ICombinedState) => state.auth.isLoggedIn);
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();

  document.title = LOGIN.TITLE;

  useEffect(() => {
    const redirectToFrom = () => {
      const from: any = location.state || { from: { pathname: '/' } };
      history.replace(from.from);
    };

    if (isLoggedIn) redirectToFrom();
  }, [isLoggedIn, history, location]);

  const loginEmailPasswordPromise = (email: string, password: string): Promise<Model.Token> => {
    return api.loginEmail({ email, password });
    // dispatch(tryLogin('umut@tripian.com', 'tripiaN123'));
  };

  const callbackSuccessLogin = (token: Model.Token) => {
    dispatch(successLogin(token));
  };

  return (
    <>
      <div className="full-center">
        <div className="login-form-wrapper">
          <LoginEmailPasswordForm
            login={loginEmailPasswordPromise}
            successLogin={callbackSuccessLogin}
            reCaptchaSiteKey="6LcSweAUAAAAAJKsnLlDnnUTLFG6cspHMI1BJCfP"
          />
          {/* <input type="button" value="Login" style={{ width: 'calc(100% - 10px)', padding: '10px', margin: '5px' }} onClick={loginHash} /> */}
          <br />
          <button
            type="button"
            onClick={() => {
              history.push('/register');
            }}
          >
            Register
          </button>
        </div>
      </div>
    </>
  );
};

export default LoginPage;
