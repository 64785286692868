// eslint-disable-next-line import/no-extraneous-dependencies
import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import ICombinedState from '../../redux/model/ICombinedState';
import InitLoading from '../../components/InitLoading/InitLoading';

import App from '../App';
import init from '../init';

interface IAppWrapperStateProps {
  loading: boolean;
}

const AppWrapper = () => {
  const { loading } = useSelector<ICombinedState, IAppWrapperStateProps>((state: ICombinedState) => {
    return {
      loading: state.layout.initLoading,
    };
  });
  const dispatch = useDispatch();

  useEffect(() => {
    init(dispatch);
  }, [dispatch]);

  const renderAppWrapper = () => {
    if (loading)
      return (
        <InitLoading>
          <span>Please Wait..</span>
        </InitLoading>
      );
    return <App />;
  };

  return <>{renderAppWrapper()}</>;
};

export default AppWrapper;
