// eslint-disable-next-line import/no-extraneous-dependencies
import Model from '@tripian/model';
// eslint-disable-next-line import/no-extraneous-dependencies
import { init, api } from '@tripian/core';
import LOCAL_STORAGE_KEYS from '../constants/LOCAL_STORAGE_KEYS';
import { changeInitLoading } from '../redux/action/layout';
import { successLogin, fetchUser } from '../redux/action/auth';

const clearLocalStorage = (): void => {
  const clearKeys = LOCAL_STORAGE_KEYS.CLEAR.split(',');
  clearKeys.forEach((clearKey) => {
    localStorage.removeItem(clearKey);
  });
};

const getLocalStorageToken = (): Model.Token | undefined => {
  try {
    const trptkn = localStorage.getItem(LOCAL_STORAGE_KEYS.TOKEN);
    if (trptkn) {
      const token: Model.Token = JSON.parse(trptkn);
      return token;
    }

    return undefined;
  } catch (error) {
    return undefined;
  }
};

const initial = (dispatch: (action: any) => void) => {
  clearLocalStorage();
  const localToken = getLocalStorageToken();
  init(undefined, undefined, localToken);

  if (localToken) {
    api
      .refreshToken()
      .then((token) => {
        dispatch(successLogin(token));
        dispatch(fetchUser());
      })
      .finally(() => {
        dispatch(changeInitLoading(false));
      });
  } else {
    dispatch(changeInitLoading(false));
  }
};

export default initial;
