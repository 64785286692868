interface IRouterHeaders {
  PATH: string;
  TITLE: string;
}

export const REGISTER: IRouterHeaders = { PATH: '/register', TITLE: 'Tripian - Register' };
export const LOGIN: IRouterHeaders = { PATH: '/login', TITLE: 'Tripian - Login' };
export const USER_PROFILE: IRouterHeaders = { PATH: '/user-profile', TITLE: 'Tripian - Profile' };
export const HOME: IRouterHeaders = { PATH: '/', TITLE: 'Tripian - Your Trip List' };
export const CREATE_TRIP: IRouterHeaders = { PATH: '/create-trip', TITLE: 'Tripian - Create New Trip' };
export const EDIT_TRIP: IRouterHeaders = { PATH: '/edit-trip', TITLE: 'Tripian - Edit Trip' };
export const BUTTERFLY: IRouterHeaders = { PATH: '/our-picks-for-you', TITLE: 'Tripian - Our Picks For You' };
export const TRIP: IRouterHeaders = { PATH: '/trip', TITLE: 'Tripian - Your Trip' };
export const NOT_FOUND: IRouterHeaders = { PATH: '*', TITLE: 'Tripian - Page Not Found :(' };
