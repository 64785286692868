import React, { useEffect } from 'react';
import { useHistory } from 'react-router';
import { useSelector, useDispatch } from 'react-redux';

import { api } from '@tripian/core';
import ICombinedState from '../../redux/model/ICombinedState';
// import UserInfo from '../../components/UserInfo/UserInfo';
import TripList from '../../components/TripList/TripList';
import {
  HOME as HOME_PATH_TITLE,
  CREATE_TRIP as CREATE_TRIP_PATH_TITLE,
  TRIP as TRIP_PATH_TITLE,
  EDIT_TRIP as EDIT_TRIP_PATH_TITLE,
} from '../../constants/ROUTER_PATH_TITLE';
import { fetchTrips } from '../../redux/action/trip';

interface IHomePage {
  title: string;
}

const HomePage: React.FC<IHomePage> = ({ title }) => {
  const tripReferences = useSelector((state: ICombinedState) => state.trip.refs);
  const dispatch = useDispatch();

  const history = useHistory();
  document.title = `${HOME_PATH_TITLE.TITLE} - ${title}`;

  useEffect(() => {
    dispatch(fetchTrips());
  }, [dispatch]);

  const cardClicked = (tripHash: string) => {
    history.push(`${TRIP_PATH_TITLE.PATH}/${tripHash}`);
  };

  const cardEditClicked = (tripHash: string) => {
    history.push(`${EDIT_TRIP_PATH_TITLE.PATH}/${tripHash}`);
  };

  const cardDeleteClicked = (tripHash: string) => {
    return api.tripDelete(tripHash).then((number) => {
      dispatch(fetchTrips());
      return number > 0;
    });
  };

  return (
    <div className="container mt10">
      <div className="row center">
        <TripList tripReferences={tripReferences} cardClicked={cardClicked} cardEditClicked={cardEditClicked} cardDeleteClicked={cardDeleteClicked} />
      </div>
      <div className="row">
        <button
          type="button"
          onClick={() => {
            history.push(CREATE_TRIP_PATH_TITLE.PATH);
          }}
        >
          New Trip
        </button>
      </div>
    </div>
  );
};

export default HomePage;
