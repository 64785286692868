// eslint-disable-next-line import/no-extraneous-dependencies
import React from 'react';

const initLoadingStyle: React.CSSProperties = {
  position: 'fixed',
  width: '100vw',
  height: '100vh',
  background: '#000088',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'column',
  color: 'white',
};

const InitLoading: React.FC = ({ children }) => {
  return <div style={initLoadingStyle}>Wellcome To Butterfly...{children}</div>;
};

export default InitLoading;
