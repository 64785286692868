import { createStore, applyMiddleware } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import thunk, { ThunkMiddleware } from 'redux-thunk';

import rootReducer from './reducer';
import ICombinedState from './model/ICombinedState';
import IAction from './model/IAction';

const createAppStore = () => {
  const store = createStore(rootReducer, composeWithDevTools(applyMiddleware(thunk as ThunkMiddleware<ICombinedState, IAction>)));
  return store;
};

export default createAppStore;
