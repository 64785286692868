// eslint-disable-next-line import/no-extraneous-dependencies
import React from 'react';

/* import { useSelector, useDispatch } from 'react-redux';
import ICombinedState from '../../../../redux/model/ICombinedState';
import { changeCenter } from '../../../../redux/action/gmaps';
import { changeDay } from '../../../../redux/action/trip';
 */

import './Menu.scss';

interface IMenu {
  listShown: boolean;
  showList: (event: React.MouseEvent<HTMLInputElement, MouseEvent>) => void;
  /* 
  changeZoomProp: (zoom: number) => void;
  changeCenterProp: (latlng: Model.Coordinate) => void;
  */
}

const Menu: React.FC<IMenu> = ({ listShown, showList }) => {
  /*   const dayIndex = useSelector((state: ICombinedState) => state.trip.day);
  const dispatch = useDispatch(); */

  return (
    <div className="menu">
      <div
        className="mitem eat"
        onClick={() => {
          // dispatch(changeZoom(12));
          // dispatch(changeDay(dayIndex + 1));
        }}
        onKeyDown={() => {}}
        role="button"
        tabIndex={0}
      >
        {' '}
      </div>
      <div
        className="mitem attraction"
        onClick={() => {
          // dispatch(changeZoom(13));
          // dispatch(changeDay(dayIndex - 1));
        }}
        onKeyDown={() => {}}
        role="button"
        tabIndex={0}
      >
        {' '}
      </div>
      <div className="mitem shape" />
      <div className={listShown ? 'circle shadow' : 'circle'}>
        <input type="button" onClick={showList} />
      </div>
      <div
        className="mitem favorites"
        onClick={() => {
          // dispatch(changeCenter({ lat: 4.643171643838715, lng: -74.09882121454933 }));
        }}
        onKeyDown={() => {}}
        role="button"
        tabIndex={0}
      >
        {' '}
      </div>
      <div
        className="mitem search"
        onClick={() => {
          // dispatch(changeCenter({ lat: 4.606727514863093, lng: -74.07123038855252 }));
        }}
        onKeyDown={() => {}}
        role="button"
        tabIndex={0}
      >
        {' '}
      </div>
    </div>
  );
};

export default Menu;
