import Model from '@tripian/model';
import { api } from '@tripian/core';

import * as AUTH_ACTIONS from '../actionType/auth';
import IAction from '../model/IAction';
import { IThunkResult, IThunkDispatch } from '../actionType/thunk';

export const successLogin = (token: Model.Token): IAction => ({
  type: AUTH_ACTIONS.SUCCESS_LOGIN,
  payload: { token },
});

export const errorLogin = (err: any): IAction => ({
  type: AUTH_ACTIONS.ERROR_LOGIN,
  payload: { error: err },
});

export const afterLogout = (): IAction => ({
  type: AUTH_ACTIONS.AFTER_LOGOUT,
  payload: null,
});

export const successUser = (user: Model.User): IAction => ({
  type: AUTH_ACTIONS.SUCCESS_USER,
  payload: { user },
});

export const errorUser = (err: any): IAction => ({
  type: AUTH_ACTIONS.ERROR_LOGIN,
  payload: { error: err },
});

export const authClear = (): IAction => ({
  type: AUTH_ACTIONS.AUTH_CLEAR,
  payload: null,
});

/**
 *
 * ThunkActions
 *
 */

/* export const thunkActionTest: ActionCreator<IThunkResult<boolean>> = (text: string) => {
  return (dispatch: Dispatch<IAction>): boolean => {
    dispatch({ type: 'SYNC Test', payload: { txt: text } });
    return true;
  };
}; */

export const fetchUser = (): IThunkResult<Promise<Model.User>> => {
  return async (dispatch: IThunkDispatch) => {
    return api
      .user()
      .then((user) => {
        dispatch(successUser(user));
        return user;
      })
      .catch((userError) => {
        errorUser(userError);
        throw new Error(`fetchUser error: ${userError}`);
      });
  };
};

export const tryLogin = (email: string, password: string): IThunkResult<void> => {
  return (dispatch: IThunkDispatch) => {
    return api
      .loginEmail({ email, password })
      .then((token) => {
        dispatch(successLogin(token));
        dispatch(fetchUser());
      })
      .catch((loginErr) => {
        dispatch(errorLogin(loginErr));
      });
  };
};

export const logout = (): IThunkResult<void> => {
  return (dispatch: IThunkDispatch) => {
    api.logout();
    dispatch(afterLogout());
    dispatch(authClear());
  };
};
