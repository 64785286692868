import { Reducer } from 'redux';
import Model from '@tripian/model';

import * as ACTION_TYPES from '../actionType/trip';
import IAction from '../model/IAction';
import ITripState from '../model/ITripState';

const initialState: ITripState = {
  refs: [],
  // ref: undefined,
  hash: undefined,
  data: undefined,
  day: 0,
  alternatives: [],
};

const tripReducer: Reducer<ITripState, IAction> = (state: ITripState = initialState, action: IAction): ITripState => {
  switch (action.type) {
    case ACTION_TYPES.CHANGE_TRIP_REFS:
      return {
        ...state,
        refs: [...action.payload.tripRefs],
      };

    /* case ACTION_TYPES.CHANGE_TRIP_REF:
      return {
        ...state,
        ref: action.payload.tripRef,
      }; */

    case ACTION_TYPES.CHANGE_HASH:
      return {
        ...state,
        hash: action.payload.hash,
        // ref: undefined,
        data: undefined,
        day: 0,
        alternatives: [],
      };

    case ACTION_TYPES.CHANGE_TRIP_DATA: {
      const tripData = action.payload.tripData as Model.Trip;
      return {
        ...state,
        data: tripData,
        alternatives: [],
      };
    }

    case ACTION_TYPES.CHANGE_DAY:
      return {
        ...state,
        day: action.payload.day,
        alternatives: [],
      };

    case ACTION_TYPES.SAVE_ALTERNATIVES: {
      const newState = { ...state };
      newState.alternatives[action.payload.stepIndex] = action.payload.alternativePois;
      return newState;
    }

    case ACTION_TYPES.TRIP_CLEAR:
      return initialState;

    default:
      return state;
  }
};

export default tripReducer;
