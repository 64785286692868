// eslint-disable-next-line import/no-extraneous-dependencies
import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

import PrivateRoute from './PrivateRoute/PrivateRoute';
import * as ROUTER_PATH_TITLE from '../../constants/ROUTER_PATH_TITLE';
import Register from '../../pages/Register/Register';
import Login from '../../pages/Login/Login';
import Home from '../../pages/Home/Home';
import Trip from '../../pages/Trip/Trip';
import CreateTrip from '../../pages/CreateUpdateTrip/CreateTrip';
import UpdateTrip from '../../pages/CreateUpdateTrip/UpdateTrip';
import NotFound from '../../pages/NotFound/NotFound';
import AppNav from '../AppNav/AppNav';
import UserProfile from '../../pages/UserProfile/UserProfile';

const AppRouter = () => {
  return (
    <Router>
      <AppNav />
      <Switch>
        <Route path={ROUTER_PATH_TITLE.REGISTER.PATH} exact component={Register} />
        <Route path={ROUTER_PATH_TITLE.LOGIN.PATH} exact component={Login} />
        <PrivateRoute path={ROUTER_PATH_TITLE.USER_PROFILE.PATH} exact component={UserProfile} />
        <PrivateRoute path={ROUTER_PATH_TITLE.HOME.PATH} exact component={Home} />
        <PrivateRoute path={`${ROUTER_PATH_TITLE.TRIP.PATH}/:hash`} exact component={Trip} />
        <PrivateRoute path={ROUTER_PATH_TITLE.CREATE_TRIP.PATH} exact component={CreateTrip} />
        <PrivateRoute path={`${ROUTER_PATH_TITLE.EDIT_TRIP.PATH}/:hash`} exact component={UpdateTrip} />
        <Route path={ROUTER_PATH_TITLE.NOT_FOUND.PATH} component={NotFound} />
      </Switch>
    </Router>
  );
};

export default AppRouter;
