import { Reducer } from 'redux';

import LOCAL_STORAGE_KEYS from '../../constants/LOCAL_STORAGE_KEYS';
import * as AUTH_ACTIONS from '../actionType/auth';
import IAction from '../model/IAction';
import IAuthState from '../model/IAuthState';

const initialState: IAuthState = {
  isLoggedIn: false,
  user: undefined,
  token: undefined,
  message: undefined,
};

const authReducer: Reducer<IAuthState, IAction> = (state: IAuthState = initialState, action: IAction): IAuthState => {
  switch (action.type) {
    case AUTH_ACTIONS.SUCCESS_LOGIN:
      localStorage.setItem(LOCAL_STORAGE_KEYS.TOKEN, JSON.stringify(action.payload.token));
      return {
        ...state,
        isLoggedIn: true,
        user: { username: action.payload.username, first_name: '', last_name: '', email: '', profile: { age: 0, answers: [] } },
        token: { access_token: action.payload.token, token_type: 'bearer', expires_in: 3600, refresh_token: '' },
        message: undefined,
      };
    case AUTH_ACTIONS.ERROR_LOGIN:
      return { ...state, message: action.payload.error };
    case AUTH_ACTIONS.AFTER_LOGOUT:
      localStorage.removeItem(LOCAL_STORAGE_KEYS.TOKEN);
      return initialState;
    case AUTH_ACTIONS.SUCCESS_USER:
      return { ...state, user: action.payload.user, message: undefined };
    case AUTH_ACTIONS.ERROR_USER:
      return { ...state, user: undefined, message: action.payload.error };
    case AUTH_ACTIONS.AUTH_CLEAR:
      return initialState;
    default:
      return state;
  }
};

export default authReducer;
