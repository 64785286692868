import { ApiConstModel } from './ApiConstModel';

const API_CONST: ApiConstModel = {
  /**
   * City
   */
  CITIES: { METHOD: 'GET', PATH: '/city', DATA_KEY: 'cities' },
  // CITIES_SEARCH: { METHOD: 'GET', PATH: '/city?search={cityName}', DATA_KEY: 'citiesSearch' }, // same with q=name:cityName or q=cityName
  // CITY: { METHOD: 'GET', PATH: '/city/{id}', DATA_KEY: 'city' },
  // CITY_FIND: { METHOD: 'GET', PATH: '/getcitybycoordinate', DATA_KEY: 'cityFind' },

  /**
   * POI
   */
  POI_CATEGORIES: { METHOD: 'GET', PATH: '/poi-category', DATA_KEY: 'poiCategories' },
  POIS: { METHOD: 'GET', PATH: '/poi', DATA_KEY: 'pois' },
  POI: { METHOD: 'GET', PATH: '/poi/{id}', DATA_KEY: 'poi' },

  /**
   * Questions
   */
  QUESTIONS: { METHOD: 'GET', PATH: '/question', DATA_KEY: 'questions' }, // questionsTrip, questionsProfile, questionsCompanion

  /**
   * Recommendations
   */
  // RECOMMENDATIONS: { METHOD: 'GET', PATH: '/recommendation', DATA_KEY: 'recommendations' },

  /**
   * User
   */
  REGISTER: { METHOD: 'POST', PATH: '/register', DATA_KEY: 'register' },
  LOGIN: { METHOD: 'POST', PATH: '/login', DATA_KEY: 'token' },
  REFRESH_TOKEN: { METHOD: 'POST', PATH: '/refresh', DATA_KEY: 'token' },
  USER: { METHOD: 'GET', PATH: '/user', DATA_KEY: 'user' },
  USER_UPDATE: { METHOD: 'PUT', PATH: '/user', DATA_KEY: 'user' },

  /**
   * Favorites
   */
  // FAVORITES: { METHOD: 'GET', PATH: '/user/favorites', DATA_KEY: 'favorites' },
  // FAVORITE_ADD: { METHOD: 'POST', PATH: '/user/favorites', CALL_BACK: { METHOD: 'GET', PATH: '/user/favorites', DATA_KEY: 'favorites' } },
  // FAVORITE_DELETE: { METHOD: 'POST', PATH: '/user/favorites', CALL_BACK: { METHOD: 'GET', PATH: '/user/favorites', DATA_KEY: 'favorites' } },

  /**
   * Companions
   */
  COMPANIONS: { METHOD: 'GET', PATH: '/user-companion', DATA_KEY: 'companions' },
  COMPANION_ADD: { METHOD: 'POST', PATH: '/user-companion', DATA_KEY: 'companions-add' },
  COMPANION_UPDATE: { METHOD: 'PUT', PATH: '/user/companion/{id}', DATA_KEY: 'companions-update' },
  COMPANION_DELETE: { METHOD: 'DELETE', PATH: '/user/companion/{id}', DATA_KEY: 'companions-delete' },

  /**
   * Trips
   */
  TRIPS: { METHOD: 'GET', PATH: '/user-trip', DATA_KEY: 'tripRefs' },
  TRIP: { METHOD: 'GET', PATH: '/trip/{hash}', DATA_KEY: 'trip' },
  TRIP_ADD: { METHOD: 'POST', PATH: '/trip', DATA_KEY: 'trip' }, // CALL_BACK: { METHOD: 'GET', PATH: '/trip/{hash}', DATA_KEY: 'trip' } },
  TRIP_UPDATE: { METHOD: 'PUT', PATH: '/trip/{hash}', DATA_KEY: 'trip' }, // CALL_BACK: { METHOD: 'GET', PATH: '/trip/{hash}', DATA_KEY: 'trip' } }, // ?? DATA_KEY: 'trip' response ??
  TRIP_DELETE: { METHOD: 'DELETE', PATH: '/trip/{hash}', DATA_KEY: 'trip' }, // , CALL_BACK: { METHOD: 'GET', PATH: '/trip/{hash}' } }, // DATA_KEY: 'trip' clear ??

  /**
   * Plan
   */
  // PLAN: { METHOD: 'GET', PATH: '/plan/{id}', DATA_KEY: 'plan' },
  PLAN_UPDATE: { METHOD: 'PUT', PATH: '/plan/{id}', DATA_KEY: 'plan-update' },

  /**
   * Step
   */
  STEP_ADD: { METHOD: 'POST', PATH: '/step' }, // DATA_KEY: 'stepAdd' },
  STEP_REPLACE: { METHOD: 'PUT', PATH: 'step/{id}' }, // DATA_KEY: 'stepUpdate' },
  STEP_DELETE: { METHOD: 'DELETE', PATH: 'step/{id}', DATA_KEY: 'step-delete' },

  /**
   * Alternatives
   */
  // ALTERNATIVES: { METHOD: 'GET', PATH: '/dailyplanpoi-alternatives', DATA_KEY: 'alternatives' }, // alternativesTrip, alternativesPlan, alternativesPlanPOI

  /**
   * Feedback
   */
  // FEEDBACK: { METHOD: 'POST', PATH: '/trip/feedback', DATA_KEY: 'feedback' }, // Never read.

  /**
   * Problem
   */
  // PROBLEM_CATEGORIES: { METHOD: 'GET', PATH: '/problem-categories', DATA_KEY: 'problemCategories' },
  // PROBLEM_REPORT: { METHOD: 'POST', PATH: '/problemReport', DATA_KEY: 'problemReport' }, // Never read.
};
export default API_CONST;
