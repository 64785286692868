/*
 * action types
 */
export const TRIP_CLEAR: string = 'TRIP_CLEAR';
export const CHANGE_TRIP_REFS: string = 'CHANGE_TRIP_REFS';
// export const CHANGE_TRIP_REF: string = 'CHANGE_TRIP_REF';
export const CHANGE_HASH: string = 'CHANGE_HASH';
export const CHANGE_TRIP_DATA: string = 'CHANGE_TRIP_DATA';
export const CHANGE_DAY: string = 'CHANGE_DAY';

// ALTERNATIVES
export const SAVE_ALTERNATIVES: string = 'SAVE_ALTERNATIVES';

// PLAN EDIT
export const STEP_DELETE: string = 'STEP_DELETE';
