/* eslint-disable import/no-extraneous-dependencies */
// eslint-disable-next-line import/no-extraneous-dependencies
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
// eslint-disable-next-line import/no-extraneous-dependencies
import { StepCard, Switch, StepTimeframe } from '@tripian/react';
import { api } from '@tripian/core';

import ICombinedState from '../../../../redux/model/ICombinedState';
// import { changeContainerVisible } from '../../../../redux/action/layout';
import { handleFocusChangeStep, handleFocusChangePoi } from '../../../../redux/action/gmaps';

import './StepList.scss';
import { changeFocusInfoVisible } from '../../../../redux/action/layout';
import SortableStepList from '../../../../components/SortableStepList/SortableStepList';

const StepList = () => {
  const [sortStepList, setSortStepList] = useState<boolean>(false);
  const [sortedStepList, setSortedStepList] = useState<Array<number>>([]);
  const { plan, alternatives, legs } = useSelector((state: ICombinedState) => ({
    plan: state.trip.data?.plans[state.trip.day],
    alternatives: state.trip.alternatives,
    legs: state.gmap.legs,
  }));
  const dispatch = useDispatch();

  return (
    <div className="stepList scrollable-y pb6">
      <div className="row mt8">
        <div className="col col12 col3-m">
          <h5>Timeframe:</h5>
        </div>
        <div className="col col12 col9-m">
          <StepTimeframe
            defaultTimeframe={{ startTime: plan?.start_time || '09:00', endTime: plan?.end_time || '21:00' }}
            callbackStepTimeframe={(timeframe) => {
              if (plan)
                api.planUpdateTime(plan.id, timeframe.startTime, timeframe.endTime).then(() => {
                  // @Todo = Refresh Plans
                });
            }}
          />
        </div>
      </div>

      <div className="switch">
        <Switch
          label="Manual Sort"
          onchange={(value) => {
            setSortStepList(value);
            if (!value && sortedStepList.length > 0 && plan)
              api.planUpdateOrders(plan.id, sortedStepList).then(() => {
                // @Todo = Refresh Plans
              });
          }}
        />
      </div>

      {!sortStepList ? (
        plan?.steps
          ?.sort((a, b) => a.order - b.order)
          .map((step, stepIndex) => (
            <div key={step.id} className="stepListCards">
              <StepCard
                key={step.id}
                step={step}
                clicked={() => {
                  dispatch(handleFocusChangeStep(step));
                  dispatch(changeFocusInfoVisible(true));
                }}
                alternativePois={alternatives[stepIndex] ? alternatives[stepIndex] : []}
                alternativePoiCardClicked={(alternativePoi) => {
                  // eslint-disable-next-line no-console
                  console.log('alternative card clicked', alternativePoi);
                  dispatch(handleFocusChangePoi(alternativePoi));
                  dispatch(changeFocusInfoVisible(true));
                }}
                alternative={(alternativePoi) => {
                  // eslint-disable-next-line no-console
                  console.log('replace button clicked', alternativePoi);
                }}
              />
              {legs[stepIndex] ? (
                <div>{`${legs[stepIndex].distance.text} - ${legs[stepIndex].duration.text} ${legs[stepIndex].travel.text}`}</div>
              ) : null}
            </div>
          ))
      ) : (
        <SortableStepList
          steps={plan?.steps ? plan?.steps : []}
          callbackSortableStepList={(list) => {
            setSortedStepList(list);
          }}
        />
      )}
    </div>
  );
};

export default StepList;
