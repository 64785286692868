// eslint-disable-next-line import/no-extraneous-dependencies
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';

import createAppStore from './redux/store';

import AppWrapper from './App/AppWrapper/AppWrapper';
import * as serviceWorker from './serviceWorker';

// eslint-disable-next-line import/no-extraneous-dependencies
import '@tripian/react/min.css';
import './index.css';

const store = createAppStore();

ReactDOM.render(
  <Provider store={store}>
    <AppWrapper />
  </Provider>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
