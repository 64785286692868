// eslint-disable-next-line import/no-extraneous-dependencies
import React from 'react';
import StepList from '../../components/StepList/StepList';

import './PlanContainer.scss';

interface IPlanContainer {
  show: boolean;
}

const PlanContainer: React.FC<IPlanContainer> = ({ show }) => {
  const planContainerClasses = ['planContainer'];
  if (show) planContainerClasses.push('planContainerOpen');
  else planContainerClasses.push('planContainerClose');

  return (
    <div className={planContainerClasses.join(' ')}>
      <StepList />
    </div>
  );
};

export default PlanContainer;
