// eslint-disable-next-line import/no-extraneous-dependencies
import React, { useState } from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import Model from '@tripian/model';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { TripCard, ConfirmModalPopup } from '@tripian/react';

interface ITripList {
  tripReferences: Model.TripReference[];
  cardClicked: (tripHash: string) => void;
  cardEditClicked: (tripHash: string) => void;
  cardDeleteClicked: (tripHash: string) => Promise<boolean>;
}

const TripList: React.FC<ITripList> = ({ tripReferences, cardClicked, cardEditClicked, cardDeleteClicked }) => {
  const [modalState, setModalState] = useState({ show: false, tripHash: '' });

  const confirmationCallback = () => {
    // @Todo refresh tripReferences
    setModalState({ show: false, tripHash: '' });
  };

  const showDeleteTripModal = modalState.show ? (
    <ConfirmModalPopup
      openModalPopup={modalState.show}
      text="Do you want to delete trip?"
      confirmButtonText="Delete Trip"
      cancelButtonText="Cancel"
      action={() => cardDeleteClicked(modalState.tripHash)}
      confirmationCallback={confirmationCallback}
      cancelCallback={() => setModalState({ show: false, tripHash: '' })}
    />
  ) : null;

  return (
    <>
      {showDeleteTripModal}
      {tripReferences.map((tripReference) => (
        <div key={tripReference.id} className="col col12 col6-m">
          <TripCard
            tripReference={tripReference}
            clicked={() => cardClicked(tripReference.trip_hash)}
            editTrip={() => cardEditClicked(tripReference.trip_hash)}
            deleteTrip={(hash) => {
              setModalState({ show: true, tripHash: hash });
            }}
          />
        </div>
      ))}
    </>
  );
};

export default TripList;
