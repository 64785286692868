import { Reducer } from 'redux';
import IAction from '../model/IAction';
import IGmapState from '../model/IGmapState';
import * as ACTION_TYPES from '../actionType/gmap';

const initialState: IGmapState = {
  // center: { lat: 52.371888305746985, lng: 4.896546591529578 }, // Amsterdam
  center: { lat: 4.616523312621425, lng: -74.07530734625516 }, // Bogota
  zoom: 13,
  boundry: [],
  focus: undefined,
  legs: [],
};

const gmapReducer: Reducer<IGmapState, IAction> = (state: IGmapState = initialState, action: IAction): IGmapState => {
  switch (action.type) {
    case ACTION_TYPES.CHANGE_CENTER:
      return {
        ...state,
        center: action.payload.latlng,
      };
    case ACTION_TYPES.CHANGE_ZOOM:
      return {
        ...state,
        zoom: action.payload.zoom,
      };
    case ACTION_TYPES.CHANGE_BOUNDRY:
      return {
        ...state,
        boundry: action.payload.boundry,
      };
    case ACTION_TYPES.HANDLE_MAP_CLICK:
      return {
        ...state,
        focus: undefined,
      };
    case ACTION_TYPES.HANDLE_MARKER_CLICK_STEP:
      return {
        ...state,
        focus: { step: action.payload.step, poi: undefined },
        // center: action.payload.step.poi.marker_coordinate,
      };
    case ACTION_TYPES.CHANGE_LEGS:
      return {
        ...state,
        legs: action.payload.legs,
      };

    case ACTION_TYPES.HANDLE_FOCUS_CHANGE_STEP:
      return {
        ...state,
        focus: { step: action.payload.focusStep, poi: undefined },
        center: action.payload.focusStep.poi.marker_coordinate,
      };
    case ACTION_TYPES.HANDLE_FOCUS_CHANGE_POI:
      return {
        ...state,
        focus: { step: undefined, poi: action.payload.focusPoi },
      };

    default:
      return state;
  }
};

export default gmapReducer;
