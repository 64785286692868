import Model, { v3 } from '@tripian/model';
import data from '../../../data/data';
import convert from '../../convert/convert';
import {
  citiesConcat,
  poisConcat,
  questionsConcat,
  tripRefsConcatOne,
  tripsConcatOne,
  companionConcatOne,
  stepDeleted,
  planUpdated,
} from './cacheHelper';

const cache = <T>(responseData: T, dataKey: string) => {
  /**
   ******************************************************************************
   *
   * Cities
   *
   */
  if (dataKey === 'cities') {
    const modelCities = convert.convertCities((responseData as unknown) as v3.City[]);
    data.cities = citiesConcat(data.cities, modelCities, true);
    /**
     *
     * Citiy Search
     *
     */
    // } else if (dataKey === 'citiesSearch') {
    //   const modelCitiesSearch = convert.convertCities((responseData as unknown) as v3.City[]);
    //   data.cities = citiesConcat(data.cities, modelCitiesSearch);
    //   data.citiesSearch = modelCitiesSearch;
    /**
     *
     * City
     *
     */
    // } else if (dataKey === 'city') {
    //   const modelCity = convert.convertCity((responseData as unknown) as v3.City);
    //   data.cities = citiesConcat(data.cities, [modelCity]);
    //   data.city = modelCity;

    /**
     ******************************************************************************
     *
     * POIS Categories
     *
     */
  } else if (dataKey === 'poiCategories') {
    const modelPoiCategories = convert.convertPoiCategories((responseData as unknown) as v3.PoiCategory[]);
    data.poiCategories = modelPoiCategories;

    /**
     ******************************************************************************
     *
     * POIS
     *
     */
  } else if (dataKey === 'pois') {
    const modelPoiArray = convert.convertPois((responseData as unknown) as v3.Poi[]);
    data.pois = poisConcat(data.pois, modelPoiArray);
    /**
     *
     * POI
     *
     */
  } else if (dataKey === 'poi') {
    const modelPoi = convert.convertPoi((responseData as unknown) as v3.Poi);
    data.pois = poisConcat(data.pois, [modelPoi]);
    // data.poi = modelPoi;

    /**
     ******************************************************************************
     *
     * USER
     *
     */
  } else if (dataKey === 'user') {
    const modelUser = convert.convertUser((responseData as unknown) as v3.User);
    data.user = modelUser;

    /**
     ******************************************************************************
     *
     * Questions
     *
     */
  } else if (dataKey === 'questionsTrip' || dataKey === 'questionsProfile' || dataKey === 'questionsCompanion') {
    const modelQuestions = convert.convertQuestions((responseData as unknown) as v3.Question[]);
    switch (dataKey) {
      case 'questionsTrip':
        data.questionsTrip = modelQuestions;
        break;
      case 'questionsProfile':
        data.questionsProfile = modelQuestions;
        break;
      case 'questionsCompanion':
        data.questionsCompanion = modelQuestions;
        break;
      default:
        break;
    }
    data.questions = questionsConcat(data.questions, modelQuestions);

    /**
     ******************************************************************************
     *
     * TripRefs
     *
     */
  } else if (dataKey === 'tripRefs') {
    const modelTripRefs = convert.convertTripRefs((responseData as unknown) as v3.TripReference[]);
    data.tripRefs = modelTripRefs;

    /**
     ******************************************************************************
     *
     * Trip
     *
     */
  } else if (dataKey === 'trip') {
    const rawModelTrip = convert.convertTrip((responseData as unknown) as v3.Trip);

    //
    // TEMP CONTROL
    //
    // Control for null poi in step
    // we remove null pois from step
    // by @Irmak
    //
    const modelTrip = { ...rawModelTrip };
    for (let i = 0; i < rawModelTrip.plans.length; i += 1) {
      const plan = rawModelTrip.plans[i];

      // TODO
      // TEMP
      const newSteps: Model.Step[] = [];
      plan.steps.forEach((step) => {
        if (step.poi) {
          newSteps.push(step);
        } else {
          // Log these pois for fix api data
          // eslint-disable-next-line no-console
          console.log('Poi null in step, step id:', step.id, 'trip hash:', rawModelTrip.trip_hash, 'trip id:', rawModelTrip.id);
        }
      });
      modelTrip.plans[i].steps = newSteps;
    }
    //
    // TEMP CONTROL
    //

    const modelTripRef: Model.TripReference = {
      id: modelTrip.id,
      trip_hash: modelTrip.trip_hash,
      city: modelTrip.city,
      trip_profile: modelTrip.trip_profile,
    };
    data.tripRefs = tripRefsConcatOne(data.tripRefs, modelTripRef);

    data.trips = tripsConcatOne(data.trips, modelTrip);
    data.trip = modelTrip;

    /**
     ******************************************************************************
     *
     * Plan
     *
     */
  } else if (dataKey === 'plan-update') {
    const rawModelPlan = convert.convertPlan((responseData as unknown) as v3.Plan);
    const tripUpdated = planUpdated(rawModelPlan, data.trip);
    data.trip = tripUpdated;

    /**
     ******************************************************************************
     *
     * Step
     *
     */
    /* } else if (dataKey === 'stepAdd') {
    const modelStep = convert.convertStep((responseData as unknown) as v3.Step);
    data.step = modelStep;
  } else if (dataKey === 'stepUpdate') {
    const modelStep = convert.convertStep((responseData as unknown) as v3.Step);
    data.step = modelStep; */
  } else if (dataKey === 'step-delete') {
    const { id: deletedStepId } = (responseData as unknown) as { id: number };
    const tripUpdated = stepDeleted(deletedStepId, data.trip);
    data.trip = tripUpdated;

    /**
     ******************************************************************************
     *
     * Companions
     *
     */
  } else if (dataKey === 'companions') {
    const modelCompanions = convert.convertCompanions((responseData as unknown) as v3.Companion[]);
    data.companions = modelCompanions;
    /**
     * Companions Add
     */
  } else if (dataKey === 'companions-add') {
    const modelCompanion = convert.convertCompanion((responseData as unknown) as v3.Companion);
    data.companions = companionConcatOne(data.companions, modelCompanion);
    /**
     * Companions Update
     */
  } else if (dataKey === 'companions-update') {
    const modelCompanion = convert.convertCompanion((responseData as unknown) as v3.Companion);
    data.companions = companionConcatOne(data.companions, modelCompanion);
    /**
     * Companions Delete
     */
  } else if (dataKey === 'companions-delete') {
    const removedCompanionId = (responseData as any).id;
    data.companions = data.companions?.filter((companion) => companion.id !== removedCompanionId);

    /**
     ******************************************************************************
     *
     * others
     *
     */
  } else {
    (data as any)[dataKey] = responseData;
  }
};

export default cache;
