import data from './data';

const dataClear = () => {
  data.register = undefined;
  data.token = undefined;
  data.user = undefined;

  // data.favorites = undefined;

  data.companions = undefined;
  data.tripRefs = undefined;
  data.trips = [];
  data.trip = undefined;
  // data.plan = undefined;
};

export default dataClear;
