/* eslint-disable import/no-extraneous-dependencies */
import React, { useState } from 'react';
import Model from '@tripian/model';
import { StepCard } from '@tripian/react';
import { SortableContainer, SortableElement, arrayMove } from 'react-sortable-hoc';

interface ISortableStepList {
  steps: Array<Model.Step>;
  callbackSortableStepList: (sortedList: Array<number>) => void;
}

const SortableStepList: React.SFC<ISortableStepList> = ({ steps, callbackSortableStepList }) => {
  const [stepList, setStepList] = useState<Array<Model.Step>>(steps);

  const SortableItem = SortableElement(({ value }: any) => (
    <li style={{ listStyleType: 'none', zIndex: 200 }}>
      <div className="p2">
        <StepCard
          step={value}
          clicked={() => {}}
          alternative={() => {}}
          alternativePois={[]}
          alternativePoiCardClicked={() => {}}
          isSortableStepListCard
        />
      </div>
    </li>
  ));

  const SortableList = SortableContainer(({ children }: any) => {
    return <ul>{children}</ul>;
  });

  const onSortEnd = ({ oldIndex, newIndex }: any) => {
    const newList = arrayMove(stepList, oldIndex, newIndex);
    const sortedArray: Array<number> = [];
    newList.forEach((i) => {
      sortedArray.push(i.id);
    });

    setStepList(newList);
    callbackSortableStepList(sortedArray);
  };

  return (
    <>
      <SortableList onSortEnd={onSortEnd} lockAxis="y">
        {stepList.map((value, index) => (
          <SortableItem key={`item-${value.id}`} index={index} value={value} />
        ))}
      </SortableList>
    </>
  );
};
export default SortableStepList;
