import React, { useMemo } from 'react';
import Gmap from '../../components/Gmap/GMap';

import './MapLayout.scss';

interface IMapLayout {
  fullWidth: boolean;
}

const MapLayout: React.FC<IMapLayout> = ({ fullWidth }) => {
  const mapClasses = ['map-container'];
  if (fullWidth) mapClasses.push('w-m-100');

  const googleMap = useMemo(() => <Gmap />, []);

  return <div className={mapClasses.join(' ')}>{googleMap}</div>;
};

export default MapLayout;
