import { DataModel } from './dataModel';
/**
 * data
 */
const data: DataModel = {
  // PROGRAM
  /**
   * initialised is default false. When core.init function called it will be true.
   */
  initialised: false,

  /**
   * If last operation of core has any error it is true else it is false.
   * You have to set back to false manually after you handle your error case.
   */
  hasError: false,

  /**
   * If last operation of core has any error it is true else it is false.
   * You have to set back to false manually after you handle your error case.
   */
  errors: [],
  logs: [],

  // CITY
  /**
   * All cities list (limit=300).
   */
  cities: undefined,
  /**
   * Search result cities list (Only with name for now).
   */
  // citiesSearch: undefined,
  /**
   * Last interested city info.
   */
  // city: undefined,
  /**
   * City info that last found by coordinate (Web pages don't use this function+data yet).
   */
  // cityFind: undefined,

  // POI
  /**
   * All POI categories of POI datas (limit=100).
   */
  poiCategories: undefined,
  /**
   * All interested pois' info since core initialised. We cache all poi infos we get from API (may come from search, dailyPlanPoi, alternatives ..).
   */
  pois: [],
  /**
   * Current interested poi's info (may return from API or cached pois array).
   */
  // poi: undefined,

  // Questions
  /**
   * It joins questionsTrip, questionsProfile and questionsCompanion.
   */
  questions: [],
  /**
   * Questions for trip when we create trip or edit trip (We cache this data).
   */
  questionsTrip: [],
  /**
   * Questions for person when we create or edit user profile (We cache this data).
   */
  questionsProfile: [],
  /**
   * Questions for companion when we create or edit companion profile (We cache this data).
   */
  questionsCompanion: [],

  /**
   * Recommendations
   */
  // recommendations: [],

  /**
   * User
   */
  register: undefined,
  token: undefined,
  user: undefined,

  /**
   * Favorites
   */
  // favorites: undefined,

  /**
   * Companions
   */
  companions: undefined,

  /**
   * Trips
   */
  tripRefs: undefined,
  trips: [],
  trip: undefined,

  /**
   * Plan
   */
  // plan: undefined,

  /**
   * Alternatives
   */
  // alternativesTrip: [],
  // alternativesPlan: [],
  // alternativesPlanPOI: [],

  /**
   * Feedback
   */
  // feedback: {},

  /**
   * Problem
   */
  // problemCategories: [],
  // problemReport: {},
};

export default data;
