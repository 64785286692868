import Model from '@tripian/model';
import data from '../../../data/data';

class Cached {
  /**
   ******************************************************************************
   *
   * Cities
   *
   */
  static cities = (): Model.City[] | undefined => {
    return (data.cities?.length || 0) > 200 ? data.cities : undefined;
  };

  // static city = (cityId: number): Model.City | undefined => {
  //   return data.cities?.find((c) => c.id === cityId);
  // };

  /**
   ******************************************************************************
   *
   * POIS Categories
   *
   */
  static poiCategories = (): Model.PoiCategory[] | undefined => {
    return data.poiCategories;
  };

  /**
   ******************************************************************************
   *
   * POI
   *
   */
  static poi = (poiId: number): Model.Poi | undefined => {
    return data.pois.find((p) => p.id === poiId);
  };

  static pois = (poiIds: number[]): Model.Poi[] | undefined => {
    if (poiIds.length === 0) return undefined;
    const filterPois = data.pois.filter((p) => poiIds.includes(p.id));
    if (filterPois.length === poiIds.length) return filterPois;
    return undefined;
  };

  /**
   ******************************************************************************
   *
   * User
   *
   */
  static user = (): Model.User | undefined => {
    return data.user;
  };

  /**
   ******************************************************************************
   *
   * Questions
   *
   */
  static questions = (category: string): Model.Question[] | undefined => {
    let questionDatas: Model.Question[] = [];
    switch (category) {
      case 'questionsTrip':
        questionDatas = data.questionsTrip;
        break;
      case 'questionsProfile':
        questionDatas = data.questionsProfile;
        break;
      case 'questionsCompanion':
        questionDatas = data.questionsCompanion;
        break;
      default:
        break;
    }
    if (questionDatas.length === 0) return undefined;

    return questionDatas;
  };

  static tripRefs = (): Model.TripReference[] | undefined => {
    return data.tripRefs;
  };

  static tripRef = (tripHash: string): Model.TripReference | undefined => {
    return data.tripRefs?.find((t) => t.trip_hash === tripHash);
  };

  static trip = (tripHash: string, minDayIndex: number): Model.Trip | undefined => {
    // let cachedTrip;

    // const currentTrip = data.trip?.trip_hash === tripHash ? data.trip : undefined;
    // if (currentTrip) {
    //   cachedTrip = currentTrip;
    // } else {
    const cachedTrip = data.trips.find((trip) => trip.trip_hash === tripHash);
    // }

    if (cachedTrip) {
      const minPlanIndex = cachedTrip.plans.length - 1 < minDayIndex ? cachedTrip.plans.length - 1 : minDayIndex;
      if (cachedTrip.plans[minPlanIndex].generated_status !== 0) {
        data.trip = cachedTrip;
        return cachedTrip;
      }
    }
    // TODO
    // else find in data.trips ... not in tripRefs !!!
    return undefined;
  };

  static plan = (planId: number): Model.Plan | undefined => {
    return data.trip?.plans.find((p) => p.id === planId);
  };

  /*   static step = (stepId: number): Model.StepDetail | undefined => {
    return data.plan?.steps?.find((s) => s.id === stepId);
  }; */
}

export default Cached;
