/* eslint-disable import/no-extraneous-dependencies */
import React from 'react';
import { SideNavigation, /*  TabMenu, */ ButtonIcons, BUTTON_TYPES, Dropdown } from '@tripian/react';

import { useHistory, withRouter } from 'react-router';
import { useSelector, useDispatch } from 'react-redux';
import ICombinedState from '../../redux/model/ICombinedState';

import {
  USER_PROFILE as USER_PROFILE_PATH_TITLE,
  HOME as HOME_PATH_TITLE,
  CREATE_TRIP as CREATE_TRIP_PATH_TITLE,
  //  LOGIN as LOGIN_PATH_TITLE
} from '../../constants/ROUTER_PATH_TITLE';

import './AppNav.scss';
import { logout } from '../../redux/action/auth';
import { changeNavbarVisible } from '../../redux/action/layout';
import { tripClear, changeDay } from '../../redux/action/trip';

const AppNav = () => {
  const {
    isLoggedIn,
    fullName,
    // username, firstName, lastName, email,
    showNavbar,
    day,
    planRefs,
  } = useSelector((state: ICombinedState) => ({
    isLoggedIn: state.auth.isLoggedIn,
    username: state.auth.user?.username,
    fullName: `${state.auth.user?.first_name} ${state.auth.user?.last_name}`,
    email: state.auth.user?.email,
    showNavbar: state.layout.navbarVisible,
    day: state.trip.day,
    planRefs: state.trip.data?.plans.map((plan) => ({ id: plan.id, generated: plan.generated_status !== 0, date: plan.date })),
  }));

  const dispatch = useDispatch();
  const history = useHistory();

  if (!isLoggedIn) return null;

  const sideNavigationMenuItems = [
    {
      title: 'User Profile',
      onClick: () => {
        history.push(USER_PROFILE_PATH_TITLE.PATH);
      },
    },
    {
      title: 'Create New Trip',
      onClick: () => {
        history.push(CREATE_TRIP_PATH_TITLE.PATH);
      },
    },
    {
      title: 'My Trips',
      onClick: () => {
        history.push(HOME_PATH_TITLE.PATH);
      },
    },
    {
      title: 'Logout',
      onClick: () => {
        dispatch(logout());
        dispatch(tripClear());
        // history.push(LOGIN_PATH_TITLE.PATH);
      },
    },
  ];

  /* const tabMenuItems = [
    {
      title: 'Plan',
      onClick: () => {},
    },
    {
      title: 'Search',
      onClick: () => {},
    },
  ]; */

  return (
    <>
      <header className="header">
        <div className="hcontainer">
          <ButtonIcons.Bars
            type={BUTTON_TYPES.TEXT}
            onClick={() => {
              dispatch(changeNavbarVisible(true));
            }}
          />
          {planRefs !== undefined ? (
            <div className="day-switch">
              <Dropdown
                options={planRefs?.map((planRef) => {
                  return { key: planRef.id, value: planRef.date };
                })}
                defaultValue={day}
                selectChange={(value, index) => {
                  // eslint-disable-next-line no-console
                  console.log(value, index);
                  dispatch(changeDay(index));
                }}
              />
            </div>
          ) : null}

          <ButtonIcons.Add type={BUTTON_TYPES.TEXT} onClick={() => {}} style={{ float: 'right' }} />
          {/* <ButtonIcons.Replace type={BUTTON_TYPES.TEXT} onClick={() => {}} style={{ float: 'right' }} /> */}
        </div>
        {/* <TabMenu menuItems={tabMenuItems} selectedIndex={0} /> */}
      </header>
      <SideNavigation
        title={fullName}
        menuItems={sideNavigationMenuItems}
        open={showNavbar}
        closed={() => {
          dispatch(changeNavbarVisible(false));
        }}
      />
    </>
  );
};

export default withRouter(AppNav);
