/* eslint-disable no-console */
// eslint-disable-next-line import/no-extraneous-dependencies
import React, { useState, useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
// eslint-disable-next-line import/no-extraneous-dependencies
import Model from '@tripian/model';
// eslint-disable-next-line import/no-extraneous-dependencies
import { api } from '@tripian/core';
// eslint-disable-next-line import/no-extraneous-dependencies
import { TripFormTemplate, Loading, Button } from '@tripian/react';
import moment from 'moment';
// import TripForm from '../../components/Forms/LoginForm/TripForm/TripForm';

import { EDIT_TRIP, HOME } from '../../constants/ROUTER_PATH_TITLE';

const emptyTripProfile: Model.TripProfile = {
  city_id: 0,
  arrival_datetime: moment(new Date())
    .add(1, 'days')
    .set({ hour: 9, minute: 0 })
    .format('YYYY-MM-DDTHH:mm:ss[Z]'),
  departure_datetime: moment(new Date())
    .add(4, 'days')
    .set({ hour: 18, minute: 0 })
    .format('YYYY-MM-DDTHH:mm:ss[Z]'),
  answers: [],
  number_of_adults: 1,
  number_of_children: 0,
  pace: Model.TRIP_PROFILE_PACE.NORMAL,
  start_coordinate: null,
  companion_ids: [],
  accommodation_address: null,
  owner: '',
  do_not_generate: 0,
};

//  const someStyle: React.CSSProperties = { textAlign: 'center', margin: 30 };

const CreateUpdateTripPage: React.FC<{ title: string }> = ({ title }) => {
  const [loading, setLoading] = useState<boolean>(true);
  const [cities, setCities] = useState<Model.City[]>([]);
  const [tripProfile, setTripProfile] = useState<Model.TripProfile>(emptyTripProfile);
  const [tripQuestions, setTripQuestions] = useState<Model.Question[]>([]);
  // const [userCompanionQuestions, setUserCompanionQuestions] = useState<Model.Question[]>([]);

  const { hash } = useParams<{ hash: string }>();
  const history = useHistory();

  if (hash) {
    document.title = `${EDIT_TRIP.TITLE} - ${title}`;
  } else {
    history.push(HOME.PATH);
  }

  useEffect(() => {
    let unmonted = false;

    const citiesPromise = api.citiesAll().then((citiesData) => {
      if (!unmonted) setCities(citiesData);
    });

    const tripQuestionsPromise = api.questionsTrip().then((tripQuestionsData) => {
      if (!unmonted) setTripQuestions(tripQuestionsData);
    });

    const tripPromise = api.trip(hash).then((tripData) => {
      if (!unmonted) setTripProfile(tripData.trip_profile);
    });

    Promise.all([citiesPromise, tripQuestionsPromise, tripPromise]).then(() => {
      setLoading(false);
    });

    // api.questionsCompanion().then((ucQuestions) => {
    //   if (!unmonted) setUserCompanionQuestions(ucQuestions);
    // });

    // const promise4 = api.companions().then((uCompanions) => {
    //   if (!unmonted) setUserCompanions(uCompanions);
    // });

    return () => {
      unmonted = true;
    };
  }, [hash]);

  const callbackTripProfile = (tripProfileParam: Model.TripProfile) => {
    // eslint-disable-next-line no-console
    console.log('callbackTripProfile');
    // eslint-disable-next-line no-console
    console.log(tripProfileParam.answers.join(','));
    setTripProfile(tripProfileParam);
  };

  // const callbackUserCompanionAdd = (userCompanion: Model.Companion) => {
  //   api
  //     .companionAdd(userCompanion)
  //     .then((data) => {
  //       // eslint-disable-next-line no-console
  //       console.log(data);
  //     })
  //     .catch((err) => {
  //       // eslint-disable-next-line no-console
  //       console.log(err);
  //     });
  // };

  const tripUpdate = () => {
    setLoading(true);

    if (tripProfile) {
      // temporary control
      const tripProfilePramas: Model.TripProfile = { ...tripProfile };
      if (tripProfilePramas.accommodation_address === null) tripProfilePramas.accommodation_address = undefined;
      if (tripProfilePramas.start_coordinate === null) tripProfilePramas.start_coordinate = undefined;

      // temporary control
      api.tripUpdate(hash, tripProfilePramas).then((trip) => {
        console.log('Trip updated', trip);
        history.push(HOME.PATH);
      });
    }
  };

  const tripCancel = () => {
    console.log('tripCancel');
    setLoading(true);
    history.goBack();
  };

  const isButtonDisabled = !tripProfile.city_id || !tripProfile.arrival_datetime || !tripProfile.departure_datetime;

  if (loading) {
    return <Loading />;
  }

  return (
    <div className="container py12">
      {tripProfile ? (
        <>
          <TripFormTemplate
            cities={cities}
            tripProfile={tripProfile}
            tripQuestions={tripQuestions}
            callbackTripProfile={callbackTripProfile}
            // userCompanionQuestions={userCompanionQuestions}
            // callbackUserCompanionAdd={callbackUserCompanionAdd}
          />
          <div className="row center">
            <div>
              <Button
                color={isButtonDisabled ? 'disabled' : 'primary'}
                style={{ marginRight: '1rem' }}
                text="Update Trip"
                disabled={isButtonDisabled}
                onClick={tripUpdate}
              />
              <Button color="primary" text="Cancel" onClick={tripCancel} />
            </div>
          </div>
        </>
      ) : null}
    </div>
  );
};

export default CreateUpdateTripPage;
