import Model from '@tripian/model';
import { api } from '@tripian/core/';

import * as TRIP_ACTIONS from '../actionType/trip';
import IAction from '../model/IAction';
import { IThunkResult, IThunkDispatch } from '../actionType/thunk';

export const changeTripRefs = (tripRefs: Model.TripReference[]): IAction => ({
  type: TRIP_ACTIONS.CHANGE_TRIP_REFS,
  payload: { tripRefs },
});

/* export const changeTripRef = (tripRef: Model.TripReference): IAction => ({
  type: TRIP_ACTIONS.CHANGE_TRIP_REF,
  payload: { tripRef },
}); */

export const changeHash = (hash: string): IAction => ({
  type: TRIP_ACTIONS.CHANGE_HASH,
  payload: { hash },
});

export const changeTripData = (tripData: Model.Trip): IAction => ({
  type: TRIP_ACTIONS.CHANGE_TRIP_DATA,
  payload: { tripData },
});

export const changeDay = (day: number): IAction => ({
  type: TRIP_ACTIONS.CHANGE_DAY,
  payload: { day },
});

export const saveAlternative = (stepIndex: number, alternativePois: Model.Poi[]): IAction => ({
  type: TRIP_ACTIONS.SAVE_ALTERNATIVES,
  payload: { stepIndex, alternativePois },
});

export const tripClear = (): IAction => ({
  type: TRIP_ACTIONS.TRIP_CLEAR,
  payload: null,
});

/**
 *
 * ThunkActions
 *
 */
export const fetchTrips = (): IThunkResult<Promise<Model.TripReference[]>> => {
  return async (dispatch: IThunkDispatch) => {
    return api
      .trips()
      .then((tripRefs) => {
        dispatch(changeTripRefs(tripRefs));
        return tripRefs;
      })
      .catch((tripsError) => {
        throw new Error(`fetchTripRefs error: ${tripsError}`);
      });
  };
};

export const fetchTrip = (hash: string): IThunkResult<Promise<Model.Trip>> => {
  return async (dispatch: IThunkDispatch) => {
    return api
      .trip(hash)
      .then((trip) => {
        dispatch(changeTripData(trip));
        return trip;
      })
      .catch((tripError) => {
        throw new Error(`fetchTrip error: ${tripError}`);
      });
  };
};

export const stepAdd = (planId: number, poiId: number, order?: number): IThunkResult<Promise<Model.Trip>> => {
  return async (dispatch: IThunkDispatch) => {
    return api
      .stepAdd(planId, poiId, order)
      .then((trip) => {
        dispatch(changeTripData(trip));
        return trip;
      })
      .catch((tripError) => {
        throw new Error(`stepAdd error: ${tripError}`);
      });
  };
};

export const stepReplace = (stepId: number, alternativePoiId: number, order?: number): IThunkResult<Promise<Model.Trip>> => {
  return async (dispatch: IThunkDispatch) => {
    return api
      .stepReplace(stepId, alternativePoiId, order)
      .then((trip) => {
        dispatch(changeTripData(trip));
        return trip;
      })
      .catch((tripError) => {
        throw new Error(`stepReplace error: ${tripError}`);
      });
  };
};

export const stepDelete = (stepId: number): IThunkResult<Promise<Model.Trip>> => {
  return async (dispatch: IThunkDispatch) => {
    return api
      .stepDelete(stepId)
      .then((trip) => {
        dispatch(changeTripData(trip));
        return trip;
      })
      .catch((tripError) => {
        throw new Error(`stepDelete error: ${tripError}`);
      });
  };
};

export const fetchAlternatives = (stepIndex: number, poiIds: number[]): IThunkResult<Promise<Model.Poi[]>> => {
  return async (dispatch: IThunkDispatch) => {
    return api
      .pois(poiIds)
      .then((pois) => {
        dispatch(saveAlternative(stepIndex, pois));
        return pois;
      })
      .catch((tripError) => {
        throw new Error(`fetchAlternatives error: ${tripError}`);
      });
  };
};
