import React, { useMemo, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
// eslint-disable-next-line import/no-extraneous-dependencies
import Model from '@tripian/model';
import { GoogleMaps } from '@tripian/react';
import ICombinedState from '../../../../redux/model/ICombinedState';
import { handleMapClick, handleClickMarkerStep, handleFocusChangePoi, changeLegs } from '../../../../redux/action/gmaps';
import { changeFocusInfoVisible } from '../../../../redux/action/layout';
// import GoogleMaps from '../../../../components/GoogleMaps/GoogleMaps';
import './Gmap.scss';

const GMap = () => {
  const { gmapZoom, gmapCenter, steps, alternativePois, focus } = useSelector((state: ICombinedState) => ({
    gmapZoom: state.gmap.zoom,
    gmapCenter: state.gmap.center,
    steps: state.trip.data?.plans[state.trip.day].steps || [],
    alternativePois: state.trip.alternatives.reduce((previousValue: Model.Poi[], currentValue: Model.Poi[]) => {
      currentValue.forEach((newAltPoi) => {
        const hasNotPoi = previousValue.findIndex((prevAltPoi) => prevAltPoi.id === newAltPoi.id) === -1;
        if (hasNotPoi) {
          const steps2 = state.trip.data?.plans[state.trip.day].steps || [];
          const isNotStep = steps2.findIndex((step) => step.poi.id === newAltPoi.id) === -1;
          if (isNotStep) previousValue.push(newAltPoi);
        }
      });
      return previousValue;
    }, []),
    focus: state.gmap.focus,
  }));

  const dispatch = useDispatch();

  const setLegsCallback = useCallback(
    (legs) => {
      // eslint-disable-next-line no-console
      console.log(legs);
      dispatch(changeLegs(legs));
    },
    [dispatch],
  );

  const gMap = useMemo(
    () => (
      <GoogleMaps
        gmapZoom={gmapZoom}
        gmapCenter={gmapCenter}
        steps={steps.filter((step) => step.poi !== null) || []}
        alternativePois={alternativePois}
        setLegs={setLegsCallback}
        focusMarkerStepId={focus?.step?.id}
        focusMarkerPoi={focus?.poi}
        mapClicked={(coordinate: Model.Coordinate) => {
          dispatch(changeFocusInfoVisible(false));
          dispatch(handleMapClick(coordinate));
        }}
        markerStepClicked={(step: Model.Step) => {
          dispatch(handleClickMarkerStep(step));
          dispatch(changeFocusInfoVisible(true));
        }}
        markerPoiClicked={(poi: Model.Poi) => {
          dispatch(handleFocusChangePoi(poi));
          dispatch(changeFocusInfoVisible(true));
        }}
      />
    ),
    [gmapZoom, gmapCenter, steps, alternativePois, setLegsCallback, focus, dispatch],
  );

  return gMap;
};

export default GMap;
