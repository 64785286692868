// eslint-disable-next-line import/no-extraneous-dependencies
import Model from '@tripian/model';

import axiosLib, { AxiosInstance } from 'axios';
import { handleHttpResponseSuccess, handleHttpResponseError } from '../easy/handle/handle';
import IXhrOptions from './IXhrOptions';

interface SuccessResponse<T> {
  status: number;
  success: boolean;
  message: string;
  data: T;
}

class XHR {
  private static instance: XHR;

  private axios: AxiosInstance = axiosLib.create();

  private token?: Model.Token;

  getToken = () => this.token;

  setToken = (token: Model.Token) => {
    this.token = token;
    this.axios.defaults.headers.common.authorization = `${token.token_type} ${token.access_token}`;
  };

  removeToken = () => {
    this.token = undefined;
    this.axios.defaults.headers.common.authorization = undefined;
  };

  private constructor(xhrOptions: IXhrOptions) {
    this.axios.defaults.baseURL = xhrOptions.url;
    this.axios.defaults.headers.common['x-api-key'] = xhrOptions.xApiKey;
    this.axios.defaults.timeout = 10000; // 2500;
    if (xhrOptions.token) {
      this.setToken(xhrOptions.token);
    }
  }

  static getInstance = (xhrOptions: IXhrOptions): XHR => {
    if (!XHR.instance) {
      XHR.instance = new XHR(xhrOptions);
      // XHR.instance.addInterceptors();
      // Object.freeze(xhrInstance);
    }
    return XHR.instance;
  };

  static refreshXhrToken = () => {
    const refreshToken = XHR.instance.token?.refresh_token;
    if (refreshToken) {
      return XHR.instance
        .req('POST')<Model.Token>('/refresh', 'token', { refresh_token: refreshToken })
        .then((token) => {
          const fullToken: Model.Token = { ...token, refresh_token: refreshToken };
          XHR.instance.setToken(fullToken);
          return fullToken;
        });
    }
    return undefined;
  };

  private get = async <T>(url: string, dataKey?: string, params?: any): Promise<T> => {
    return this.axios
      .get<SuccessResponse<T>>(url, { params })
      .then<T>((httpResponse) => handleHttpResponseSuccess<T>(httpResponse.data, dataKey))
      .catch((errorResponse) => handleHttpResponseError(errorResponse, dataKey));
  };

  private post = async <T>(url: string, dataKey?: string, params?: any): Promise<T> => {
    return this.axios
      .post<SuccessResponse<T>>(url, params)
      .then<T>((httpResponse) => {
        const tokenResponse = handleHttpResponseSuccess<T>(httpResponse.data, dataKey);
        if (dataKey === 'token') this.setToken((tokenResponse as unknown) as Model.Token);
        return tokenResponse;
      })
      .catch((errorResponse) => handleHttpResponseError(errorResponse, dataKey));
  };

  private put = async <T>(url: string, dataKey?: string, params?: any): Promise<T> => {
    return this.axios
      .put<SuccessResponse<T>>(url, params)
      .then<T>((httpResponse) => handleHttpResponseSuccess<T>(httpResponse.data, dataKey))
      .catch((errorResponse) => handleHttpResponseError(errorResponse, dataKey));
  };

  private delete = async <T>(url: string, dataKey?: string, params?: any): Promise<T> => {
    return this.axios
      .delete<SuccessResponse<T>>(url, { params })
      .then<T>((httpResponse) => handleHttpResponseSuccess<T>(httpResponse.data, dataKey))
      .catch((errorResponse) => handleHttpResponseError(errorResponse, dataKey));
  };

  req = (httpMethod: string) => {
    if (httpMethod === 'GET') return this.get;
    if (httpMethod === 'POST') return this.post;
    if (httpMethod === 'PUT') return this.put;
    if (httpMethod === 'DELETE') return this.delete;
    return this.get;
  };
}

export default XHR;
