// eslint-disable-next-line import/no-extraneous-dependencies
import React, { useEffect } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';

import Model from '@tripian/model';
import { api } from '@tripian/core';
import { RegisterFormTemplate } from '@tripian/react';

import ICombinedState from '../../redux/model/ICombinedState';

import { REGISTER, LOGIN } from '../../constants/ROUTER_PATH_TITLE';

const RegisterPage = () => {
  const isLoggedIn = useSelector((state: ICombinedState) => state.auth.isLoggedIn);
  const history = useHistory();
  const location = useLocation();

  document.title = REGISTER.TITLE;

  useEffect(() => {
    const redirectToFrom = () => {
      const from: any = location.state || { from: { pathname: '/' } };
      history.replace(from.from);
    };

    if (isLoggedIn) redirectToFrom();
  }, [isLoggedIn, history, location]);

  const registerPromise = (registerRequest: Model.RegisterRequest): Promise<Model.User> => {
    return api.register(registerRequest);
  };

  const callbackSuccessRegister = (user: Model.User) => {
    // eslint-disable-next-line no-console
    console.log('user', user);
    history.push(LOGIN.PATH);
  };

  return (
    <>
      <div className="full-center">
        <div className="login-form-wrapper">
          <RegisterFormTemplate
            register={registerPromise}
            successRegister={callbackSuccessRegister}
            reCaptchaSiteKey="6LcSweAUAAAAAJKsnLlDnnUTLFG6cspHMI1BJCfP"
          />
          {/* <input type="button" value="Login" style={{ width: 'calc(100% - 10px)', padding: '10px', margin: '5px' }} onClick={loginHash} /> */}
          <br />
          <button
            type="button"
            onClick={() => {
              history.push('/login');
            }}
          >
            Login
          </button>{' '}
        </div>
      </div>
    </>
  );
};

export default RegisterPage;
